import React, { useCallback, useEffect, useMemo, useState } from "react";
import useBus from "use-bus";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LoadingContainer from "./LoadingContainer";
import useApiService from "../hooks/useApiService";
import useStore from "../hooks/useStore";
import { Logger } from "../services/Logger";
import Events from "../enum/Events";
import ROUTES from "../enum/Routes";
import useInitialState from "../hooks/useInitialState";
import { Toaster } from "../services/toastService";

export default function DashboardContainer() {
	const {
		activeDarlehenId,
		updateStore,
	} = useStore();

	const location = useLocation();
	const { darlehensdaten } = useInitialState();

	const {
		isRequesting,
		getDarlehensdaten
	} = useApiService();

	const navigate = useNavigate();
	const [loadingText, setLoadingText] = useState<string>("");

	const isDashboardIndex = useMemo((): boolean => location.pathname.replace(/\/$/, '') === ROUTES.DASHBOARD.replace(/\/$/, ''), [location]);

	const setDarlehensdaten = useCallback(
        () => {
			if (!isRequesting && activeDarlehenId?.length) {
				setLoadingText("Darlehensdaten werden geladen...");
				getDarlehensdaten(activeDarlehenId)
					.then((response) => {
						Logger.info("[DashboardContainer] Current Darlehensdaten", response);

						if (isDashboardIndex) {
							navigate(`${ROUTES.DASHBOARD}/${response.id}`, {
								state: { preventRedirect: true }
							});
						}
					})
					.catch((error) => {
						navigate(ROUTES.ERROR, { state: { message: error } });
					});
			}
		},
		[
			isRequesting,
			activeDarlehenId,
		]
	);

	useEffect((): void => {
		Logger.info("[DashboardContainer] Get Vertragsdaten");
		setDarlehensdaten();
	}, [activeDarlehenId]);

	useEffect((): void => {
		Logger.info("[DashboardContainer] Providing Data for SubRoutes");
	}, [Logger]);

	useEffect(() => {
		if (activeDarlehenId?.length && isDashboardIndex) {
			updateStore({
				activeDarlehenId: "",
				darlehensdaten
			});
		}
	}, [isDashboardIndex]);

	useBus(
		Events.SET_DARLEHENSDATEN,
		() => {
			setDarlehensdaten();
		},
		[activeDarlehenId]
	);

	useBus(
		Events.PREPARE_DARLEHENSDATEN,
		({ value }) => {
			Logger.debug("[DashboardContainer] darlehenId", value.darlehenId);
			updateStore({ activeDarlehenId: value.darlehenId });
		},
		[]
	);

	return (
		<>
			{isRequesting ? (
				<LoadingContainer
					isLoading={true}
					className="loading-container--standalone"
					contentClassName="loading-container__content--text-only"
				>
					{loadingText}
				</LoadingContainer>
			) : (
				<Outlet />
			)}
			<Toaster />
		</>
	);
}

import React from 'react';
import {ReactComponent as IconInfo} from '../../../assets/images/icon-info.svg';
import {useModal} from '../Modal';
import ModalFooter from '../ModalFooter';

export default function HintAzvValueTooHigh() {
    const {data} = useModal();
    const {onClose} = data;

    return (
        <>
            <div className="modal__content">
                <IconInfo className="modal__headline-icon modal__headline-icon--centered" />

                <p className="text text--m text--centered">
                    Die Freigabe von Auszahlungen über 100.000,00 EUR ist im Sachbearbeiter-Frontend nicht möglich.
                    Bitte erfassen Sie die Auszahlung im Host.
                </p>
            </div>

            <ModalFooter buttons={[{type: 'close', onClick: () => onClose?.()}]} />
        </>
    );
}

import React from 'react';
import {Button} from '@crossbuilders/form-library/components';
import uniqueId from '../../services/uniqueIdService';
import classNames from 'classnames';
import {useModal} from './Modal';

interface ButtonConfig {
    type: 'confirm' | 'abort' | 'close' | 'save' | 'finish' | 'forward';
    onClick?: (event: MouseEvent | TouchEvent) => void;
}

interface ModalFooterProps {
    buttons: ButtonConfig[];
    spread?: boolean;
}

const ModalFooter = ({buttons, spread}: ModalFooterProps) => {
    const {setModalOpen} = useModal();

    const closeModal = () => {
        setModalOpen(false);
    };

    const BUTTON_CONFIG = {
        confirm: {
            buttonText: 'Bestätigen',
            primary: true,
        },
        close: {
            buttonText: 'Schließen',
            primary: true,
        },
        abort: {
            buttonText: 'Abbrechen',
            primary: false,
        },
        save: {
            buttonText: 'Speichern',
            primary: true,
        },
        finish: {
            buttonText: 'Beenden',
            primary: true,
        },
        forward: {
            buttonText: 'Weiter',
            primary: true,
        },
    };

    return (
        <div
            className={classNames({
                modal__footer: true,
                'modal__footer--spread': spread,
            })}
        >
            {buttons.map((button: ButtonConfig) => (
                <Button
                    key={uniqueId()}
                    onClick={(event: MouseEvent | TouchEvent) => {
                        if (typeof button.onClick === 'function') {
                            button.onClick(event);
                            return;
                        }

                        closeModal();
                    }}
                    type="button"
                    className={classNames({
                        button: true,
                        'button--primary': BUTTON_CONFIG[button.type].primary,
                    })}
                >
                    {BUTTON_CONFIG[button.type].buttonText}
                </Button>
            ))}
        </div>
    );
};

export default ModalFooter;

import {Actions, ActionsProps, Store} from "../@types/store";
import {Tab} from "../@types/dashboard";
import {DarlehenPerson} from "../@types/data/Darlehensdaten";

const actions = ({dispatch, state}: ActionsProps): Actions => ({
    updateStore(payload: Store) {
        dispatch({
            type: "updateStore",
            payload
        });
    },
    updateAutoAuth(autoAuth: boolean) {
        dispatch({
            type: "updateAutoAuth",
            payload: autoAuth
        });
    },
    setCurrentTabIndex(currentTabIndex: number) {
        dispatch({
            type: "setCurrentTabIndex",
            payload: currentTabIndex
        });
    },
    setTabs(tabs: Array<Tab>) {
        dispatch({
            type: "setTabs",
            payload: tabs
        });
    },
    updateActiveDarlehen(newDarlehenFields: Store) {
        dispatch({
            type: 'updateStore',
            payload: {
                darlehensdaten: {
                    darlehensnehmer: state.darlehensdaten.darlehensnehmer,
                    darlehen: state.darlehensdaten.darlehen.map((darlehen: DarlehenPerson) => {
                        if (darlehen.id === state.activeDarlehenId) {
                            return {...darlehen, ...newDarlehenFields};
                        }

                        return darlehen;
                    }),
                }
            }
        });
    }
});

export default actions;

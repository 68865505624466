export type Status =
    'erledigt'
    | 'erfüllt'
    | 'Verzicht'
    | 'in Arbeit'
    | 'erhalten'
    | 'eingereicht'
    | 'offen'
    | 'storniert'
    | 'geplant'
    | 'ausgeführt'
    | 'angelegt';
type Label = 'done' | 'wip' | 'open' | 'error' | 'not-required' | undefined;

const getStatusLabel = (status: Status): Label => {
    switch (status) {
        case 'erfüllt':
        case 'erledigt':
            return 'done';

        case 'in Arbeit':
        case 'eingereicht':
        case 'erhalten':
        case 'ausgeführt':
            return 'wip';

        case 'offen':
        case 'geplant':
            return 'open';

        case 'storniert':
            return 'error';

        case 'angelegt':
        case 'Verzicht':
            return 'not-required';

        default:
            return undefined;
    }
};

export default getStatusLabel;

import {useEffect, useRef} from 'react';
import {useLocalStorage} from 'react-use';
import {useLocation} from 'react-router-dom';
import ROUTES from '../enum/Routes';

interface CurrentRouteReturn {
    prevRoute: string | undefined;
    removeRouteFromStore: () => Promise<void>;
}

export default function useCurrentRoute(): CurrentRouteReturn {
    const isMounted = useRef(false);

    const EXCLUDED_ROUTES = [ROUTES.INDEX, ROUTES.AUTHORIZE, ROUTES.ERROR];
    const location = useLocation();
    const [prevRoute, setPrevRoute, removePrevRoute] = useLocalStorage(
        'sb-service-prevRoute',
        isMounted ? undefined : '',
    );

    useEffect(() => {
        const {pathname = ''} = location;

        if (!EXCLUDED_ROUTES.includes(pathname)) {
            setPrevRoute(location.pathname);
        }

        if (!isMounted.current) {
            isMounted.current = true;
        }
    }, [isMounted, location]);

    const removeRouteFromStore = async () => {
        await removePrevRoute();
    };

    return {
        prevRoute,
        removeRouteFromStore,
    };
}

interface RoutesObject {
	INDEX: string;
	DASHBOARD: string;
	PAYOUT_ORDER: string;
	AUTHORIZE: string;
	ERROR: string;
	NOTIFICATIONS: string;
}

const ROUTES: RoutesObject = Object.freeze({
	INDEX: "/",
	DASHBOARD: "/dashboard",
	PAYOUT_ORDER: "auszahlungsauftrag",
	AUTHORIZE: "/authorize",
	ERROR: "/error",
	NOTIFICATIONS: "benachrichtigungen",
});

export default ROUTES;

import React from 'react';

import '../../assets/scss/6_components/general/_key-value-box.scss';

import LoanDiagram from './LoanDiagram';
import ROUTES from '../../enum/Routes';
import {DarlehenPerson, DarlehensdatenPerson} from '../../@types/data/Darlehensdaten';
import InternalLink from '../Routing/InternalLink';

export interface DashboardHeaderProps {
    activeDarlehen: DarlehenPerson;
    advisor?: boolean;
}

const DashboardHeader = ({activeDarlehen}: DashboardHeaderProps) => {
    const {
        verzichtBetrag,
        darlehensbetrag,
        auszahlungsbetrag,
        maximalerAuszahlungsbetrag,
    } = activeDarlehen;

    const getFullName = (person: DarlehensdatenPerson) => {
        const fullname = `${person.vorname} ${person.nachname}`;

        if (person?.titel) {
            return `${person.anrede} ${person.titel} ${fullname}`;
        }

        return `${person.anrede} ${fullname}`;
    };

    return (
        <>
            <div className="two-column-grid">
                <h2 className="headline headline--level-2">
                    Finanzierungsübersicht
                </h2>
                <InternalLink to={`${ROUTES.DASHBOARD}/`} className="link link--with-chevron link--align-top-right">
                    Anderes Darlehen wählen
                </InternalLink>
            </div>

            <div className="two-column-grid">
                <div className="border-box">
                    <div>
                        {activeDarlehen.darlehensnehmer.map((person: DarlehensdatenPerson) => (
                            <span key={person.vorname} className="text text--s text--m text--bold">
                                {getFullName(person)}
                            </span>
                        ))}

                        <span className="text text--s text--bold">Vertrag:&nbsp;{activeDarlehen.externeId}</span>
                    </div>

                    <div>
                        {/* Produktart / Produktschlüßel */}
                        <span className="text text--s">Finanzierung Neubau</span>
                        <span className="text text--s">Bauspardarlehen Standard</span>
                    </div>
                </div>

                <LoanDiagram
                    verzichtBetrag={verzichtBetrag || 0}
                    darlehensbetrag={darlehensbetrag || 0}
                    auszahlungsbetrag={auszahlungsbetrag || 0}
                    maximalerAuszahlungsbetrag={maximalerAuszahlungsbetrag || 0}
                />
            </div>
        </>
    );
};

export default DashboardHeader;

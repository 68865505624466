import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import LoadingContainer from '../containers/LoadingContainer';
import useAuthenticate from '../hooks/useAuthenticate';
import {useTokenHandlerCookies} from '../hooks/useTokenHandlerCookies';
import {Logger} from '../services/Logger';
import ROUTES from '../enum/Routes';
import refreshAccessToken from '../services/refreshAccessToken';
import {useSessionContext} from '../services/SessionContextProvider';

export default function AuthorizePage() {
    const {csrfToken, expiresIn} = useTokenHandlerCookies();
    const {userType, isAuthenticated, getCsrfToken, getUserLoginState} = useAuthenticate();
    const [sessionContext] = useSessionContext();
    const navigate = useNavigate();

    const authorize = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            getCsrfToken()
                .then(() => {
                    Logger.debug('[AuthorizePage] csrfToken', csrfToken);

                    if (!expiresIn?.length || expiresIn === '0') {
                        reject();
                    }

                    if (csrfToken?.length) {
                        getUserLoginState()
                            .then((response) => {
                                Logger.debug('[Preflight][hook] response', response);
                                Logger.debug('[Preflight][hook] Redirecting', sessionContext.redirectPath);

                                if (isAuthenticated) {
                                    resolve();
                                } else {
                                    reject();
                                }
                            })
                            .catch((errorResponse) => {
                                Logger.debug('[Prefligh][hook] failed', errorResponse);

                                if (errorResponse.status === 401) {
                                    return navigate(ROUTES.ERROR);
                                }

                                if (errorResponse.status === 403) {
                                    reject();
                                }
                            });
                    }
                });
        });
    };

    useEffect(() => {
        authorize()
            .then(() => navigate(sessionContext.redirectPath, {replace: true}))
            .catch(() => refreshAccessToken(userType ?? ''));
    }, []);

    return (
        <div style={{
            textAlign: 'center',
            margin: '0 auto',
        }}>
            <LoadingContainer
                contentClassName="loading-container__content--text-only"
                className="loading-container--standalone"
                isLoading
            >
                Anmeldedaten werden geprüft
            </LoadingContainer>
        </div>
    );
}

import {useState} from 'react';
import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {useNavigate} from 'react-router-dom';

import {
    ZahlungsauftragResponse,
    ApiServiceProps,
    ApiServiceResponse,
    BenachrichtigungResponse,
    DarlehensdatenResponse,
    DarlehensIdResponse,
    DokumentResponse,
    IbanValidierungsResponse,
    KontingentResponse,
    PreflightResponse,
    SubmitProofOfUseResponse,
    UpdateDokumentResponse,
    VerarbeitungsregelResponse,
} from '../@types/services/apiService';
import {
    Dokument,
    Benachrichtigung,
    ProofOfUseRequest,
    VerarbeitungsregelRequest,
} from '../@types/data/Darlehensdaten';

import EP1 from '../mocks/ep1-sb-response.json';
import EP5 from '../mocks/ep5-response.json';
import EP7 from '../mocks/ep7-response.json';
import EP15 from '../mocks/ep15-response.json';
import EP21 from '../mocks/ep21-response.json';
import EP22 from '../mocks/ep22-response.json';
import EP24 from '../mocks/ep24-response.json';
import EP26 from '../mocks/ep26-response.json';
import useAuthenticate from './useAuthenticate';

import useInitialState from './useInitialState';
import {Logger} from '../services/Logger';
import useStore from './useStore';
import ROUTES from '../enum/Routes';
import Messages, {MessagesEnum} from '../enum/Messages';

const {REACT_APP_TOKENHANDLER_DIK, REACT_APP_DEMO_MODE} = process.env;

const useApiService = (additionalConfig?: ApiServiceProps): ApiServiceResponse => {
    const [isRequesting, setIsRequesting] = useState(false);
    const {updateStore} = useStore();
    const initialState = useInitialState();
    const navigate = useNavigate();
    const {signOut} = useAuthenticate();

    const demoMode = REACT_APP_DEMO_MODE === 'true';

    const api: AxiosInstance = axios.create({
        baseURL: REACT_APP_TOKENHANDLER_DIK,
        withCredentials: true,
        xsrfCookieName: '_csrf-token',
        validateStatus: (status) => status >= 200 && status < 400,
        headers: {
            'Content-Type': 'application/json',
        },
        ...additionalConfig,
    });

    api.interceptors.response.use(
        (response) => {
            setIsRequesting(false);
            Logger.debug('[API Interceptor] Response', response);
            return response;
        },
        (error) => {
            setIsRequesting(false);

            if (error?.code === 'ERR_CANCELED') return Promise.reject({message: 'ERR_CANCELED'});
            if (!error.response) return Promise.reject();

            const response: AxiosResponse = error.response;
            Logger.debug('[API Interceptor] Error', response);

            const id: string = getErrorIdFromResponse(response?.data ?? response);
            const message = id !== undefined ? Messages[id as keyof MessagesEnum] : 'Unerwarteter Fehler';

            if (['M24', 'M33', 'M35'].includes(id)) {
                return Promise.reject({message});
            }

            if ([500, 400].includes(response.status)) {
                navigate(ROUTES.ERROR, {state: {message}});
            }

            if (response.status === 401) {
                signOut();
            }

            return Promise.reject(response);
        },
    );

    const preflight = (): PreflightResponse => {
        Logger.info('[Preflight]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            api.get('/preflight')
                .then((response) => resolve(response))
                .catch((error) => reject(error))
                .finally(() => {
                    setIsRequesting(false);
                });
        });
    };

    const getErrorIdFromResponse = (response: any): string => {
        if (response?.id) return response.id as string;
        if (response?.messages?.id) return response.messages.id as string;
        if (response?.messages?.length > 1) return response.messages[0].id as string;
        return '';
    };

    const getError = (error: Record<string, any>): string => {
        if (error?.message === 'ERR_CANCELED') return '';

        return error?.message?.data ?? error?.data ?? error?.message ?? 'Unerwarteter Fehler';
    };

    /* EP26 */
    const getDarlehenId = (query: string): DarlehensIdResponse => {
        Logger.info('[getDarlehenId]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    await updateStore({
                        activeDarlehenId: EP26.id,
                    });

                    Logger.info('[useApiService] getDarlehenId', EP26);

                    setIsRequesting(false);
                    resolve(EP26);
                }, 1000);

                return;
            }

            api.get(`/darlehen/vertragsnummer/${query}`)
                .then(async (response) => {
                    Logger.debug('[getDarlehenId] Response', response.data);

                    await updateStore({
                        activeDarlehenId: response.data.id,
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[getDarlehenId] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const getDarlehensdaten = (darlehenId: string): DarlehensdatenResponse => {
        Logger.info('[getDarlehensdaten]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    await updateStore({
                        darlehensdaten: {
                            darlehensnehmer: EP1.darlehensnehmer,
                            darlehen: [EP1] || initialState.darlehensdaten.darlehen,
                        },
                    });

                    Logger.info('[useApiService] loadedDarlehensdaten', EP1);

                    setIsRequesting(false);
                    resolve(EP1);
                }, 1000);

                return;
            }

            api.get(`/darlehen/${darlehenId}`)
                .then(async (response) => {
                    Logger.debug('[getDarlehensdaten] Response', response.data);

                    await updateStore({
                        darlehensdaten: {
                            darlehensnehmer: response.data.darlehensnehmer[0],
                            darlehen: [response.data],
                        },
                    });

                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[getDarlehensdaten] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP13 */
    const getDokument = (
        darlehenId: string,
        dokumentId: string,
    ): DokumentResponse => {
        Logger.info('[getDokument]');

        return new Promise((resolve, reject) => {
            if (demoMode) {
                resolve(new Blob());
            }

            setIsRequesting(true);

            api.get(`/darlehen/${darlehenId}/dokument/${dokumentId}`, {responseType: 'blob'})
                .then((response) => {
                    Logger.debug('[getDokument] Response', response.data);
                    resolve(new Blob([response.data], {type: 'application/pdf'}));
                })
                .catch((error) => {
                    Logger.error('[getDokument] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP19 */
    const getZahlungsDokument = (
        darlehenId: string,
        zahlungId: string,
    ): DokumentResponse => {
        Logger.info('[getZahlungsDokument]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                resolve(new Blob());
            }

            api.get(`/darlehen/${darlehenId}/zahlung/${zahlungId}`, {responseType: 'blob'})
                .then((response) => {
                    Logger.debug('[getZahlungsDokument] Response', response.data);
                    resolve(new Blob([response.data], {type: 'application/pdf'}));
                })
                .catch((error) => {
                    Logger.error('[getZahlungsDokument] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    const updateDokumente = (
        darlehenId: string,
        dokumente: Array<Dokument>,
    ): UpdateDokumentResponse => {
        Logger.info('[updateDokumente]', darlehenId);

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    const responseMock = {
                        rechnungssumme: 1200,
                        dokumente,
                    };

                    Logger.debug('[useApiService] updateDokumente', responseMock);

                    setIsRequesting(false);
                    resolve(responseMock);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/dokument/korrektur`, {dokumente})
                .then((response) => {
                    Logger.debug('[updateDokumente] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[updateDokumente] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const submitProofOfUse = (
        darlehenId: string,
        gewerke: Array<ProofOfUseRequest>,
    ): SubmitProofOfUseResponse => {
        Logger.info('[submitProofOfUse]', darlehenId, gewerke);

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    Logger.debug('[useApiService] submitProofOfUse', EP15);

                    setIsRequesting(false);
                    resolve(EP15);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/bautenstandSpeichern`, {gewerke})
                .then((response) => {
                    Logger.debug('[submitProofOfUse] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[submitProofOfUse] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const getBenachrichtigungen = (
        darlehenId: string,
    ): BenachrichtigungResponse => {
        Logger.info('[getBenachrichtigungen]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] getBenachrichtigungen', EP24);

                    setIsRequesting(false);
                    resolve(EP24 as { benachrichtigungen: Array<Benachrichtigung> });
                }, 1000);

                return;
            }

            api.get(`/darlehen/${darlehenId}/benachrichtigungen`)
                .then((response) => {
                    Logger.debug('[getBenachrichtigungen] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[getBenachrichtigungen] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const setStatusGelesen = (
        darlehenId: string,
        ids: Array<string>,
    ): BenachrichtigungResponse => {
        Logger.info('[setStatusGelesen]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] setStatusGelesen', EP21);

                    setIsRequesting(false);
                    resolve(EP21 as { benachrichtigungen: Array<Benachrichtigung> });
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/benachrichtigungen/statusgelesen`, {ids})
                .then((response) => {
                    Logger.debug('[setStatusGelesen] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[setStatusGelesen] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const validateIban = (iban: string): Promise<IbanValidierungsResponse> => {
        Logger.info('[validateIban]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] validateIban', EP5);

                    setIsRequesting(false);
                    resolve(EP5 as IbanValidierungsResponse);
                }, 1000);

                return;
            }

            api.post(`/iban/details`, {iban})
                .then((response) => {
                    Logger.debug('[validateIban] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[validateIban] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    /* EP16 */
    const updateKontingent = (
        darlehenId: string,
        kontingent: number,
    ): KontingentResponse => {
        Logger.info('[updateKontingent]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    if (!EP1?.kontingente) return;

                    const kontingente = [
                        ...EP1.kontingente,
                        {
                            id: 769868769899,
                            betrag: kontingent,
                            erstelltDurch: 'A986598765',
                            erstelltAm: '2022-09-01T00:00:00+00:00',
                        },
                    ];

                    Logger.debug('[useApiService] updateKontingent', kontingente);

                    setIsRequesting(false);

                    resolve({kontingente});
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/kontingentAnpassen`, {kontingent})
                .then((response) => {
                    Logger.debug('[updateKontingent] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[updateKontingent] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    /* EP22 */
    const zahlungsauftragFreigeben = (
        darlehenId: string,
        auftragId: string,
    ): ZahlungsauftragResponse => {
        Logger.info('[zahlungsauftragFreigeben]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] zahlungsauftragFreigeben', EP22);

                    setIsRequesting(false);

                    resolve(EP22);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/auszahlungsauftrag/${auftragId.toString()}/freigeben`)
                .then((response) => {
                    Logger.debug('[updateKontingent] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[updateKontingent] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const zahlungsauftragAblehnen = (
        darlehenId: string,
        auftragId: string,
        aenderungen: Array<string>,
    ): ZahlungsauftragResponse => {
        Logger.info('[zahlungsauftragAblehnen]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] zahlungsauftragAblehnen', EP7);

                    setIsRequesting(false);

                    resolve(EP7);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/auszahlungsauftrag/${auftragId.toString()}/ablehnen`, {aenderungen})
                .then((response) => {
                    Logger.debug('[updateKontingent] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[updateKontingent] Error', getError(error));
                    reject(getError(error));
                });
        });
    };

    const updateVerarbeitungsregeln = (
        darlehenId: string,
        data: VerarbeitungsregelRequest,
    ): VerarbeitungsregelResponse => {
        Logger.info('[updateVerarbeitungsregeln]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            api.post(`/darlehen/${darlehenId}/verarbeitungsregelnAnpassen`, data)
                .then((response) => {
                    Logger.debug('[updateVerarbeitungsregeln] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[updateVerarbeitungsregeln] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    return {
        isRequesting,
        preflight,
        getDarlehenId,
        getDarlehensdaten,
        getDokument,
        getZahlungsDokument,
        updateDokumente,
        submitProofOfUse,
        getBenachrichtigungen,
        setStatusGelesen,
        updateKontingent,
        validateIban,
        zahlungsauftragFreigeben,
        zahlungsauftragAblehnen,
        updateVerarbeitungsregeln,
    };
};

export default useApiService;

import React, {useEffect, useState} from 'react';
import {dispatch} from 'use-bus';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {Input} from '@crossbuilders/form-library/components';
import LoadingWheel from '../components/Layout/LoadingWheel';

import useStore from '../hooks/useStore';
import {Logger} from '../services/Logger';
import Events from '../enum/Events';
import schemaRepository from '../services/schemaRepository';
import useValidationResolver from '../hooks/useValidationResolver';
import useApiService from '../hooks/useApiService';

const DarlehenSearchPage = () => {
    const {activeDarlehenId} = useStore();
    const {getDarlehenId} = useApiService();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const schema = schemaRepository();
    const {control, errors, setError, getValues, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onBlur',
        resolver: useValidationResolver(yup.object({
            search: schema.number.vertragsnummer,
        })),
        defaultValues: {
            search: null,
        },
    });

    const submitForm = () => {
        setIsLoading(true);
        getDarlehenId(getValues('search')).catch((error: string) => {
            setError('search' as never, {message: error});
            setIsLoading(false);
        });
    };

    useEffect(
        (): void => {
            if (activeDarlehenId) {
                dispatch({type: Events.PREPARE_DARLEHENSDATEN, value: {darlehenId: activeDarlehenId}});
            }
        },
        [activeDarlehenId],
    );

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, []);

    useEffect(() => {
        Logger.info('[DarlehenListPage]');
        Logger.debug('[DarlehenListPage] activeDarlehenId', activeDarlehenId);
    }, [Logger, activeDarlehenId]);

    return (
        <>
            <p className="text">
                Bitte geben Sie eine Vetragsnummer ein:
            </p>

            <form onSubmit={handleSubmit(submitForm)} className="form-row form-row--search" autoComplete="off">
                <Input
                    type="search"
                    control={control}
                    errors={errors}
                    id="darlehen-search-input"
                    name="search"
                    placeholder="Vertragsnummer"
                    minLength="9"
                    maxLength="10"
                    inputClass="form-element form-element--search"
                    rowClass="form-row--search-input-row"
                />

                <button
                    type="submit"
                    disabled={isLoading}
                    className="button button--primary button--align-right button--flex"
                >
                    {isLoading ? (
                        <LoadingWheel isLoading color="white" />
                    ) : null}

                    <span>Öffnen</span>
                </button>
            </form>
        </>
    );
};

export default DarlehenSearchPage;

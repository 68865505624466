import {useCallback} from 'react';
import useBus from 'use-bus';
import {useKey} from 'react-use';
import {AuthSwitchProps} from '../@types/authentication';
import ROUTES from '../enum/Routes';
import {Logger} from '../services/Logger';
import useCurrentRoute from './useCurrentRoute';
import Events from '../enum/Events';

export default function useAuthSwitch({onAuthCookieChange}: AuthSwitchProps): void {
	const {REACT_APP_BASE_PATH} = process.env;
	const {removeRouteFromStore} = useCurrentRoute();

	const handleAuthSwitch = useCallback(() => {
		Logger.debug('[handleAuthSwitch]');

		window.location.href = window.location.origin + REACT_APP_BASE_PATH + ROUTES.INDEX;
	}, []);

	const handleDocumentKeyPress = useCallback(
		(event: KeyboardEvent): void => {
			Logger.debug('[handleDocumentKeyPress]');

			event.preventDefault();
			removeRouteFromStore().then(() => {
				onAuthCookieChange();
			});
		},
		[onAuthCookieChange]
	);

	const keyEventTest = (event: KeyboardEvent): boolean => {
		const {repeat, metaKey, shiftKey, ctrlKey, key} = event;

		if (repeat) {
			return false;
		}

		return (metaKey || ctrlKey) && shiftKey && key.toLowerCase() === 'i';
	};

	useBus(
		Events.SWITCH_AUTHENTICATION,
		() => {
			handleAuthSwitch();
		},
		[]
	);

	useKey(keyEventTest, handleDocumentKeyPress, {event: 'keydown'});
}

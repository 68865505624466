import React, {useMemo, useState, useEffect, useCallback, useRef, MutableRefObject} from 'react';
import {format, parseISO} from 'date-fns';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Tooltip as Tippy, TooltipProps} from 'react-tippy';

import '../../assets/scss/6_components/layout/_table.scss';

import {ReactComponent as SortIcon} from '../../assets/images/icon-arrow-down-red.svg';
import {ReactComponent as IconSee} from '../../assets/images/eye-light.svg';
import {ReactComponent as IconEdit} from '../../assets/images/icon-edit.svg';

import {Aktivitaet, Zahlungen} from '../../@types/data/Darlehensdaten';
import {TableOfPayoutsProps} from '../../@types/dashboard';
import {Button} from '@crossbuilders/form-library/components';
import useStore from '../../hooks/useStore';
import useDocumentDownload from '../../hooks/useDocumentDownload';
import {useModal} from '../Modal/Modal';
import NoDataComponent from './partials/NoDataComponent';
import {transformToNumberFormat} from '../../services/numberConverterService';
import getStatusLabel, {Status} from '../../services/getStatusLabel';

const Tooltip = Tippy as unknown as React.FC<TooltipProps>;

const payoutsStatusDictionary: { [key: string]: string } = {
    angelegt: 'angelegt',
    geplant: 'geplant',
    storniert: 'storniert',
    ausgefuehrt: 'ausgeführt',
};

const TableOfPayouts = (props: TableOfPayoutsProps) => {
    const {data, introText, offeneAktivitaeten} = props;
    const [rowData, setRowsData] = useState<Zahlungen[]>([]);
    const {activeDarlehenId} = useStore();
    const modalContext = useModal();
    const {setModalOpen, setModalType, setData: setModalData} = modalContext;

    const {downloadZahlungsDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    useEffect(() => {
        setRowsData(data);
    }, []);

    const editAllowed = useCallback((rowId) => offeneAktivitaeten.some(
        ({referenz}: Aktivitaet) => referenz.toString() === rowId.toString(),
    ), [offeneAktivitaeten]);

    const columns = useMemo(
        (): Array<TableColumn<Zahlungen>> => [
            {
                name: 'Auftragsdatum',
                selector: (row) => row.auftragsdatum,
                format: (row) => format(parseISO(row.auftragsdatum), 'dd.MM.y'),
                sortable: true,
                wrap: true,
                grow: 1.5,
                id: "datum",
            },
            {
                name: 'Zahlungsempfänger',
                selector: (row) => row.empfaengerName,
                cell: (row) => row.empfaengerName,
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Betrag',
                selector: (row) => row.betrag,
                cell: (row) => `${transformToNumberFormat({number: Math.abs(row.betrag ?? 0), digits: 2})} €`,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Status',
                cell: (row: Zahlungen) => {
                    const status = payoutsStatusDictionary[row.frontendStatus];
                    let className = `tag tag--${getStatusLabel(status as Status)}`;
                    if (editAllowed(row.id)) {
                        className = 'tag tag--primary';
                    }

                    return <span className={className}>{status}</span>;
                },
                selector: (row) => row.frontendStatus ?? '-',
                sortable: true,
                width: '150px',
            },
            {
                name: 'Bearbeiter',
                cell: (row: Zahlungen) => row.abgelehntDurch ?? row.freigegebenDurch ?? '',
                selector: (row) => row.abgelehntDurch ?? row.freigegebenDurch ?? '',
                sortable: true,
                width: '150px',
            },
            {
                name: '',
                cell: (row: Zahlungen) => (
                    <div className="table__cell-with-icons">
                        {row.id && editAllowed(row.id) ? (
                            <Tooltip title="Auszahlung bearbeiten" position="top-end" theme="light">
                                <Button
                                    onClick={() => {
                                        setModalType('editPayout');
                                        setModalData(row);
                                        setModalOpen(true);
                                    }}
                                    type="button"
                                >
                                    <IconEdit width="18" height="18" style={{color: 'white'}} />
                                </Button>
                            </Tooltip>
                        ) : null}

                        <Tooltip title="Auszahlung anzeigen" position="top-end" theme="light">
                            <a
                                href={`#${row.id}`}
                                ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                                onClick={(event) => {
                                    event.preventDefault();

                                    if (downloadButton.current && row.id) {
                                        downloadZahlungsDokument(
                                            downloadButton.current,
                                            activeDarlehenId,
                                            row.id.toString(),
                                        );
                                    }
                                }}
                            >
                                <IconSee width="20" height="20" />
                            </a>
                        </Tooltip>
                    </div>
                ),
                right: true,
                width: '80px',
                allowOverflow: true,
            },
        ],
        [modalContext, editAllowed],
    );

    const conditionalRowStyles = [
        {
            when: (row: Zahlungen) => !!row.id && editAllowed(row.id),
            style: {
                backgroundColor: 'rgb(252, 126, 81)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    return (
        <div>
            <p className="text text--s tabs-content__intro">
                {introText}
            </p>

            <div className="table">
                <DataTable
                    className="table"
                    columns={columns}
                    data={rowData}
                    responsive
                    noDataComponent={<NoDataComponent />}
                    conditionalRowStyles={conditionalRowStyles}
                    sortIcon={<SortIcon />}
                    progressPending={false}
                    defaultSortFieldId="datum"
                    defaultSortAsc={false}
                />
            </div>
        </div>
    );
};

export default TableOfPayouts;

import React from 'react';
import {useModal} from '../Modal';
import ModalFooter from "../ModalFooter";
import {transformToNumberFormat} from "../../../services/numberConverterService";
import {format, parseISO} from "date-fns";

const SeePayout: React.FC = () => {
    const {data} = useModal();

    return (
        <>
            <div className="modal__content">
                <h2 className="headline headline--level-2">Auszahlung</h2>

                <dl className="modal__definition-list">
                    <dt>
                        Zahlungsempfänger
                    </dt>
                    <dd>
                        {data?.empfaengerName ?? '-'}
                    </dd>
                    <dt>
                        IBAN
                    </dt>
                    <dd>
                        {data?.empfaengerIban ?? '-'}
                    </dd>
                    <dt>
                        Kreditinstitut
                    </dt>
                    <dd>
                        {data?.empfaengerKreditinstitut ?? '-'}
                    </dd>
                    <dt>
                        Betrag in EUR
                    </dt>
                    <dd>
                        {data?.betrag ? `${transformToNumberFormat({number: data?.betrag ?? 0, digits: 2})} €` : '-'}
                    </dd>
                    <dt>
                        Datum
                    </dt>
                    <dd>
                        {data?.auftragsdatum ? format(parseISO(data.auftragsdatum), "dd.MM.y") : '-'}
                    </dd>
                </dl>
            </div>

            <ModalFooter buttons={[{type: 'close'}]} />
        </>
    );
};

export default SeePayout;

import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Input, NumberInput, Dropdown} from '@crossbuilders/form-library/components';
import {transformToNumberFormat} from '../../../services/numberConverterService';
import useIsMounted from '../../../hooks/useIsMounted';

interface EditableTableCellProps {
    row: any,
    column: string,
    isEditingMode: boolean,
    onChange: (rowId: string, column: string, value: string) => void,
    choices?: {},
    type?: string,
}

const EditableTableCell: React.FC<EditableTableCellProps> = (props: EditableTableCellProps) => {
    const {row, column, isEditingMode, onChange, type, choices} = props;

    const isMounted = useIsMounted();
    const {control, watch} = useForm({
        defaultValues: {
            [column]: row[column],
        },
    });

    const value: string = watch(column);

    useEffect(() => {
        if (isMounted() && value !== row[column]) {
            onChange(row.id, column, value ?? '');
        }
    }, [value, isMounted]);

    if (type === 'dropdown') {
        return (
            <Dropdown
                id={`${row.id}__${column}`}
                name={column}
                control={control}
                rowClass="table__cell-dropdown"
                choices={choices ?? {}}
                defaultValue="default"
                disabled={!isEditingMode}
            />
        );
    }

    if (isEditingMode) {
        if (type === 'number') {
            return <NumberInput rowClass="" delay={0} unitLabel="€" id={`${row.id}__${column}`} name={column}
                                control={control} />;
        }

        return (
            <Input rowClass="" type="text" id={`${row.id}__${column}`} name={column} control={control} />
        );
    }

    if (type === 'number') {
        return <span>{`${transformToNumberFormat({number: value ?? 0, digits: 2})} €`}</span>;
    }

    return <span>{value}</span>;
};

export default EditableTableCell;

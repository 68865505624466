import React, {useState, useMemo} from 'react';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {NumberInput} from '@crossbuilders/form-library/components';

import {ReactComponent as IconRefresh} from '../../assets/images/icon-refresh.svg';

import useStore from '../../hooks/useStore';
import useValidationResolver from '../../hooks/useValidationResolver';
import schemaRepository from '../../services/schemaRepository';
import useApiService from '../../hooks/useApiService';
import {postError, postSuccess} from '../../services/toastService';
import TableOfKontingente from '../Tables/TableOfKontingente';
import {useModal} from '../Modal/Modal';

const ContingentForm = () => {
    const {activeDarlehen, activeDarlehenId, updateActiveDarlehen} = useStore();
    const {updateKontingent} = useApiService();
    const [isLoading, setIsLoading] = useState(false);

    const defaultKontingent = activeDarlehen.kontingente?.find(({id}) => id === activeDarlehen.referenzAktuellesKontingent) ?? null;
    const maxKontingent = useMemo(() => {
        return Math.min(100000, activeDarlehen.maximalerAuszahlungsbetrag);
    }, [activeDarlehen]);

    const schema = schemaRepository();
    const {
        control,
        handleSubmit,
        errors,
        setValue,
        getValues,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onBlur',
        resolver: useValidationResolver(yup.object({
            kontingent: schema.number.required,
        })),
        defaultValues: {
            kontingent: defaultKontingent?.betrag ?? 0,
        },
    });

    const modalContext = useModal();
    const {setModalOpen, setModalType, setData: setModalData} = modalContext;

    const fillContingent = () => {
        setValue('kontingent' as never, maxKontingent);
    };

    const submitForm = () => {
        setIsLoading(true);

        updateKontingent(activeDarlehenId, getValues('kontingent'))
            .then(async (data) => {
                const {kontingente} = data;
                await updateActiveDarlehen({kontingente});

                postSuccess('Änderungen wurden erfolgreich gespeichert.');
            })
            .catch((error: string) => postError(error))
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)} className="form-row form-row--flex">
                <div className="form-row form-row--50">
                    <NumberInput
                        id="kontingent"
                        name="kontingent"
                        control={control}
                        unitLabel="€"
                        inputClass="form-element form-element--textarea form-element--align-right"
                        placeholder="Kontingent"
                        value={0}
                        max={maxKontingent}
                        maxErrorMessage={'Der maximal zur Verfügung stehende Betrag darf nicht überschritten werden.'}
                        errors={errors}
                    />
                </div>
                <div className="form-row form-row--50">
                    <button type="button" className="button" onClick={() => {
                        setModalType('confirmation');
                        setModalData({
                            text: 'Möchten Sie das Kontingent auf den höchstmöglichen Betrag auffüllen?',
                            confirmButtonText: 'Ja',
                            onConfirm: () => fillContingent(),
                        });
                        setModalOpen(true);
                    }}>
                        <IconRefresh width="20" height="20" style={{marginRight: '1rem'}} /> Kontingent auffüllen
                    </button>

                    <button type="submit" className="button button--primary button--align-right">
                        Speichern
                    </button>
                </div>
            </form>

            <h3 className="headline headline--level-3">
                Änderungshistorie Kontingent
            </h3>

            <TableOfKontingente
                data={activeDarlehen?.kontingente ? activeDarlehen.kontingente : []}
                isLoading={isLoading}
            />
        </>
    );
};

export default ContingentForm;

export interface MessagesEnum {
    M0: string;
    M1: string;
    M2: string;
    M3: string;
    M4: string;
    M5: string;
    M6: string;
    M7: string;
    M8: string;
    M9: string;
    M10: string;
    M11: string;
    M12: string;
    M13: string;
    M32: string;
    M33: string;
    M34: string;
    M35: string;
}

const Messages: MessagesEnum = Object.freeze({
    M0: 'Dieser Service steht aktuell nicht zur Verfügung. Bitte versuchen Sie es später erneut.',
    M1: 'Für diesen Service liegt kein passender Vertrag vor.',
    M2: 'Es ist ein Fehler aufgetreten.',
    M3: 'Eine Legitimation ist nicht möglich.',
    M4: 'Ein Auszahlungsauftrag ist nicht möglich.',
    M5: 'Die IBAN ist nicht gültig.',
    M6: 'Für den Zahlungsauftrag ist keine Freigabe möglich.',
    M7: 'Das Dateiformat ist ungültig.',
    M8: 'Die Datei ist zu groß.',
    M9: 'Der Upload ist nicht möglich.',
    M10: 'Das Dokument kann nicht ausgelesen werden.',
    M11: 'Der Bautenstandsbericht ist unvollständig oder enthält nicht erlaubte Inhalte.',
    M12: 'Das Einreichen des Bautenstands ist nicht möglich.',
    M13: 'Die Anpassung der Daten ist nicht möglich.',
    M24: 'Für diese Funktion liegt keine ausreichende Kompetenz vor.',
    M32: 'Eingangswerte ungültig.',
    M33: 'Der Ordnungsbegriff ist ungültig. Bitte prüfen Sie Ihre Eingabe.',
    M34: 'Das Dokument ist nicht gültig oder beschädigt.',
    M35: 'Sie haben keine Berechtigung für den Aufruf dieser Seite.',
});

export default Messages;

interface EventsObject {
	PREPARE_DARLEHENSDATEN: string,
	SET_DARLEHENSDATEN: string;
	SWITCH_AUTHENTICATION: string;
	SUBMIT_FILE_UPLOAD: string;
	SAVE_UPLOADED_FILE: string;
	IS_MOUNTED: string;
	SUBMIT_AUSZAHLUNGSAUFTRAG_FORM: string;
}

const Events: EventsObject = {
	PREPARE_DARLEHENSDATEN: "@dik/PREPARE_DARLEHENSDATEN",
	SET_DARLEHENSDATEN: "@dik/SET_VERTRAGSDATEN",
	SWITCH_AUTHENTICATION: "@dik/SWITCH_AUTHENTICATION",
	SUBMIT_FILE_UPLOAD: "@dik/SUBMIT_FILE_UPLOAD",
	SAVE_UPLOADED_FILE: "@dik/SAVE_UPLOADED_FILE",
	IS_MOUNTED: "@dik/IS_MOUNTED",
	SUBMIT_AUSZAHLUNGSAUFTRAG_FORM: "@dik/SUBMIT_AUSZAHLUNGSAUFTRAG_FORM",
};

export default Events;

import React from 'react';
import {ReactComponent as Logo} from '../../assets/images/WW_logo-wuestenrot-2019.svg';
import {Button} from '@crossbuilders/form-library/components';
import {ReactComponent as IconClose} from '../../assets/images/xmark-large-light.svg';
import {useModal} from './Modal';

interface ModalHeaderProps {
    withLogo?: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
    const {withLogo} = props;
    const {setModalOpen} = useModal();

    return (
        <div className="modal__header">
            {withLogo && <Logo />}

            <Button onClick={() => setModalOpen(false)} type="button" className="modal__close">
                <IconClose />
            </Button>
        </div>
    );
};

ModalHeader.defaultProps = {
    withLogo: false,
};

export default ModalHeader;

import {useCallback} from 'react';
import {ObjectSchema} from 'yup';

export default function useValidationResolver(validationSchema: ObjectSchema<any>) {
    return useCallback(
        async (data) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any | undefined | Array<{path: string; type: string | null; message: string}>) {
                return {
                    values: {},
                    errors: errors.inner?.reduce(
                        (
                            allErrors: {},
                            currentError: {path: string; type: string | null; message: string}
                        ) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type || 'validation',
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );
}

import {parseISO} from 'date-fns';
import {useMemo} from 'react';
import {useStore as useStoreContext} from '../store';
import {Store} from '../@types/store';
import {Auszahlungsvoraussetzung, Baufortschritt, DarlehenPerson, Dokument} from '../@types/data/Darlehensdaten';
import {Tab} from '../@types/dashboard';

export default function useStore(): Store {
    const {state, actions} = useStoreContext();

    const activeDarlehen: DarlehenPerson | null = useMemo(() => {
        if (state.activeDarlehenId && state.darlehensdaten.darlehen?.length) {
            return state.darlehensdaten.darlehen.find(
                (darlehen: any) => darlehen.id === state.activeDarlehenId,
            );
        }

        return null;
    }, [state.activeDarlehenId, state.darlehensdaten]);

    const currentTab: Tab = useMemo(() => {
        if (!('tabs' in state)) {
            return null;
        }

        return state.tabs[state.currentTabIndex];
    }, [state.tabs, state.currentTabIndex]);

    const baufortschritt: Baufortschritt | null = useMemo(() => {
        const baufortschritte = activeDarlehen?.baufortschritte ?? [];

        if (baufortschritte) {
            baufortschritte.sort((a: Baufortschritt, b: Baufortschritt) => {
                return parseISO(b.datum).getTime() - parseISO(a.datum).getTime();
            });
        }

        return baufortschritte?.[0] ?? null;
    }, [activeDarlehen]);

    const rechnungen: Array<Dokument> = useMemo(() => {
        if (!activeDarlehen?.auszahlungsvoraussetzungen) return [];

        const rechnungenRows = activeDarlehen?.auszahlungsvoraussetzungen
            .filter((item: Auszahlungsvoraussetzung) => item.kategorieRechnung);

        return rechnungenRows
            .reduce((rechnungen: Array<Dokument>, currentRow: Auszahlungsvoraussetzung) => [
                ...rechnungen, ...currentRow.dokumente,
            ], []);
    }, [activeDarlehen]);

    return {
        ...state,
        ...actions,
        activeDarlehen,
        currentTab,
        baufortschritt,
        rechnungen,
    };
}

import React, {useMemo, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import ROUTES from '../enum/Routes';
import AuthorizePage from '../pages/AuthorizePage';
import DarlehenContainer from './DarlehenContainer';
import DarlehenSearchPage from '../pages/DarlehenSearchPage';
import DashboardContainer from './DashboardContainer';
import ErrorPage from '../pages/ErrorPage';
import NotFoundPage from '../pages/NotFoundPage';
import ProtectedRoute from '../components/Routing/ProtectedRoute';

import useAuthSwitch from '../hooks/useAuthSwitch';
import useCurrentRoute from '../hooks/useCurrentRoute';
import useAuthenticate from '../hooks/useAuthenticate';
import useQuery from '../hooks/useQuery';

import {AuthSwitchProps} from '../@types/authentication';
import {Logger} from '../services/Logger';
import {useSessionContext} from '../services/SessionContextProvider';
import AdvisorPage from '../pages/AdvisorPage';
import LandingPage from '../pages/LandingPage';

export default function IndexContainer(onAuthCookieChange: AuthSwitchProps) {
    const {prevRoute} = useCurrentRoute();
    const {isAuthenticated} = useAuthenticate();
    const [sessionContext, updateSessionContext] = useSessionContext();
    const navigate = useNavigate();
    const query = useQuery();

    const setRedirectPath = (path: string) => {
        updateSessionContext({...sessionContext, redirectPath: path});
    };

    const defaultProtectedRouteProps = useMemo(
        () => ({
            isAuthenticated,
            authenticationPath: ROUTES.AUTHORIZE,
            redirectPath: prevRoute || sessionContext.redirectPath,
            setRedirectPath,
        }),
        [isAuthenticated],
    );

    useEffect(() => {
        Logger.debug('[IndexContainer] set defaultProtectedRouteProps', defaultProtectedRouteProps);
    }, [defaultProtectedRouteProps]);

    useEffect(() => {
        const hasError = query.get('error');

        if (hasError) {
            navigate(ROUTES.ERROR);
        }
    }, [query]);

    useAuthSwitch(onAuthCookieChange);

    return (
        <Routes>
            <Route index element={<LandingPage />} />
            <Route path={ROUTES.AUTHORIZE} element={<AuthorizePage />} />
            <Route element={<ProtectedRoute {...defaultProtectedRouteProps} />}>
                <Route path={ROUTES.DASHBOARD} element={<DashboardContainer />}>
                    <Route path={':darlehenId'} element={<DarlehenContainer />}>
                        <Route index element={<AdvisorPage />} />
                    </Route>
                    <Route index element={<DarlehenSearchPage />} />
                </Route>
            </Route>
            <Route path={ROUTES.ERROR} element={<ErrorPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

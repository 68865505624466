import {InitialStoreParams} from "../@types/store";

export default function useInitialState(): InitialStoreParams {
    return {
        currentTabIndex: 0,
        tabs: [],
        activeDarlehenId: "",
        darlehensdaten: {
            darlehensnehmer: [],
            darlehen: [],
        },
        benachrichtigungen: [],
    };
}

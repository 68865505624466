import React, {useEffect, useMemo, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Button} from '@crossbuilders/form-library/components';
import useBus, {dispatch} from 'use-bus';
import {format, parseISO} from 'date-fns';

import {ReactComponent as SortIcon} from '../../assets/images/icon-arrow-down-red.svg';
import {ReactComponent as IconImage} from '../../assets/images/icon-image.svg';
import {ReactComponent as IconCheck} from '../../assets/images/check-regular.svg';
import {ReactComponent as IconEdit} from '../../assets/images/icon-edit.svg';

import '../../assets/scss/6_components/layout/_table.scss';

import {TableOfProofOfUseProps} from '../../@types/dashboard';
import {Baufortschritt, Gewerk, ProofOfUseRequest} from '../../@types/data/Darlehensdaten';
import LoadingWheel from '../Layout/LoadingWheel';
import NoDataComponent from './partials/NoDataComponent';
import useIsMounted from '../../hooks/useIsMounted';
import useApiService from '../../hooks/useApiService';
import useStore from '../../hooks/useStore';
import EditableTableCell from './partials/EditableTableCell';
import TableOfProofOfUseActionCell from './partials/TableOfProofOfUseActionCell';
import {GewerkeStatusKey} from '../../enum/Gewerke';
import Events from '../../enum/Events';

interface TableComponentProps {
    data: Array<Gewerk>,
    isEditingMode: boolean,
    isLoading: boolean,
    loadingText: string,
    onChange: (rowId: string, column: string, value: any) => void,
}

type StatusChoices = {offen?: string, 'teilweise fertiggestellt'?: string, fertiggestellt?: string};

const TableComponent = (props: TableComponentProps) => {
    const {data, isEditingMode, isLoading, loadingText, onChange} = props;

    const getStatushoices = (currentStatus: string): StatusChoices => {
        let availableChoices: StatusChoices = {
            [GewerkeStatusKey.OFFEN]: 'Offen',
            [GewerkeStatusKey.TEILWEISE_FERTIGGESTELLT]: 'Teilweise fertiggestellt',
            [GewerkeStatusKey.FERTIGGESTELLT]: 'Fertiggestellt',
        };

        switch (currentStatus) {
            case GewerkeStatusKey.OFFEN:
                break;
            case GewerkeStatusKey.TEILWEISE_FERTIGGESTELLT:
                delete availableChoices.offen;
                break;
            case GewerkeStatusKey.FERTIGGESTELLT:
            default:
                delete availableChoices.offen;
                delete availableChoices['teilweise fertiggestellt'];
        }

        return availableChoices;
    };

    const columns = useMemo(
        (): TableColumn<Gewerk>[] => [
            {
                name: 'Gewerk',
                selector: (row) => row.bezeichnung,
                cell: (row) => row.bezeichnung,
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Status',
                selector: (row) => row.baustatus,
                cell: (row) => <EditableTableCell
                    row={row}
                    column="baustatus"
                    onChange={onChange}
                    isEditingMode={isEditingMode}
                    type="dropdown"
                    choices={getStatushoices(row.baustatus)}
                />,
                grow: 0.8,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Bilder',
                selector: (row) => row.dokumente.length,
                cell: (row) => (
                    <div className="table__cell-with-icon">
                        {row.dokumente.length ? (
                            <>
                                <IconImage width="18" height="18" />
                                <span className="text">{row.dokumente.length}</span>
                            </>
                        ) : null}
                    </div>
                ),
                grow: 0.4,
                sortable: true,
            },
            {
                name: '',
                cell: (row) => <TableOfProofOfUseActionCell row={row} isEditingMode={isEditingMode} />,
                right: true,
                width: '40px',
                allowOverflow: true,
            },
        ],
        [data, isEditingMode],
    );

    return (
        <div className="table">
            <DataTable
                className="table"
                columns={columns}
                data={data}
                responsive
                noDataComponent={<NoDataComponent />}
                sortIcon={<SortIcon />}
                progressPending={isLoading}
                progressComponent={<LoadingWheel isLoading={isLoading} text={loadingText} />}
            />
        </div>
    );
};

const TableOfProofOfUse = (props: TableOfProofOfUseProps) => {
    const {data, introText, allowEditing, defaultDatum} = props;
    const {submitProofOfUse} = useApiService();
    const {activeDarlehenId} = useStore();
    const isMounted = useIsMounted();

    const [rowData, setRowData] = useState<Array<Gewerk>>([]);
    const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [changedValues, setChangedValues] = useState<any>(null);
    const [datum, setDatum] = useState<any>(null);

    const saveEdit = async () => {
        if (!isDirty) {
            setIsEditingMode(false);
            return;
        }

        setLoadingText('Bitte warten…');
        setIsLoading(true);

        const gewerke: Array<ProofOfUseRequest> = changedValues.map((row: Gewerk) => ({
            baustatus: row.baustatus,
            bezeichnung: row.bezeichnung,
            bilderDokumentenIds: row.dokumente.map((dokument) => dokument.id),
        }));

        const response = await submitProofOfUse(activeDarlehenId, gewerke);

        dispatch(Events.SET_DARLEHENSDATEN);

        setIsEditingMode(false);

        if (isMounted() && response.baufortschritte.length) {
            response.baufortschritte.sort((a: Baufortschritt, b) => {
                return parseISO(b.datum).getTime() - parseISO(a.datum).getTime();
            });

            setDatum(response.baufortschritte[0].datum);
            setRowData(response.baufortschritte[0].gewerke);
        }
    };

    useEffect(() => {
        if (isMounted()) {
            setRowData(data);
            setDatum(defaultDatum);
        }
    }, [data, isMounted, defaultDatum]);

    useBus(
        Events.SAVE_UPLOADED_FILE,
        ({rowId, dokument}) => {
            const rowIndex = data.findIndex(({id}) => id === rowId);
            const newValues: Array<Gewerk> = [...data];

            if (!newValues[rowIndex].dokumente) {
                newValues[rowIndex].dokumente = [];
            }

            newValues[rowIndex].dokumente.push(dokument);

            setChangedValues(newValues);
            setRowData(newValues);
            setIsDirty(true);
        },
        [data],
    );

    const lastChangedDate = useMemo(() => {
        return datum ? format(parseISO(datum), 'dd.MM.y') : null;
    }, [datum]);

    if (!rowData) return null;

    return (
        <div>
            <p className="text text--s tabs-content__intro">
                {introText}
            </p>

            <p className="text text--s">
                {`Zuletzt geändert: ${lastChangedDate}`}
            </p>

            {isEditingMode ? (
                <div className="tabs-content__actions">
                    <Button onClick={saveEdit} type="button" className="button button--small button--primary">
                        {isLoading ? (
                            <LoadingWheel color="white" isLoading />
                        ) : (
                            <IconCheck width="20" height="20" fill="#fff" />
                        )}
                        <span style={{marginLeft: '10px'}}>Bautenstandsbericht abschicken</span>
                    </Button>
                </div>
            ) : (allowEditing ? (
                <div className="tabs-content__actions">
                    <Button onClick={() => setIsEditingMode(true)} type="button"
                            className="button button--small button--ghost">
                        <IconEdit width="20" height="20" fill="#f84914"
                                  style={{marginRight: '10px'}} /> Bautenstandsbericht bearbeiten
                    </Button>
                </div>
            ) : null)}

            <TableComponent
                data={rowData}
                isEditingMode={isEditingMode}
                isLoading={isLoading}
                loadingText={loadingText}
                onChange={(rowId: string, column: string, value: string | number) => {
                    const rowIndex = data.findIndex(({id}) => id.toString() === rowId);
                    const newValues: Array<Gewerk> = [...data];
                    // @ts-ignore
                    newValues[rowIndex][column as keyof Gewerk] = value;

                    setChangedValues(newValues);

                    setIsDirty(true);
                }}
            />
        </div>
    );
};

export default TableOfProofOfUse;

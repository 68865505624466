import React, {ReactElement, useEffect, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import placeholderImage from '../assets/images/error-page-image.png';
import ROUTES from '../enum/Routes';
import {useTokenHandlerCookies} from '../hooks/useTokenHandlerCookies';

interface ErrorLocationState {
	message?: string;
}

const ErrorPage = (): ReactElement => {
	const navigate = useNavigate();
	const currentLocation = useLocation();
	const {deleteCsrfToken, deleteExpiresIn} = useTokenHandlerCookies();
	const {state} = currentLocation;

	const errorLocationState = state as ErrorLocationState;

	const errorTitle = useMemo(
		() =>
			errorLocationState?.message ??
			'Hier ist etwas schief gelaufen! Versuchen Sie es zu einem späteren Zeitpunkt erneut.',
		[errorLocationState]
	);

	const handleNavigateIndex = async () => {
		await deleteCsrfToken();
		await deleteExpiresIn();
		navigate(ROUTES.AUTHORIZE);
	};

	useEffect(() => {
		navigate(1);
	}, []);

	return (
		<div className="error-page">
			<img className="error-page__image" src={placeholderImage} alt="Platzhalter" />

			<div className="error-page__content-wrapper">
				<h1 className="error-page__headline">{errorTitle}</h1>
				<p className="error-page__content">
					Sie können das Auszahlungsportal daher leider nicht nutzen. Das Fenster können Sie nun
					schließen. Um das Kundenportal zu nutzen, klicken Sie bitte{' '}
					<a href="https://www.wuestenrot.de/de/mein_wuestenrot.html" className="link">
						hier
					</a>
					.
				</p>

				<button className="button error-page__button" onClick={handleNavigateIndex}>
					Erneut anmelden
				</button>
			</div>
		</div>
	);
};

export default ErrorPage;

import useApiService from '../hooks/useApiService';

export default function useDocumentDownload() {
    const {getDokument, getZahlungsDokument} = useApiService();

    const downloadFileBlob = (fileBlob: Blob): void => {
        const blobUrl = window.URL.createObjectURL(fileBlob);

        const newWindow = window.open(blobUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        }
    };

    return {
        downloadDokument(currentTarget: HTMLAnchorElement, darlehenId: string, dokumentId: string) {
            getDokument(darlehenId, dokumentId)
                .then((fileBlob: Blob) => downloadFileBlob(fileBlob));
        },
        downloadZahlungsDokument(currentTarget: HTMLAnchorElement, darlehenId: string, zahlungId: string) {
            getZahlungsDokument(darlehenId, zahlungId)
                .then((fileBlob: Blob) => downloadFileBlob(fileBlob));
        },
    };
}

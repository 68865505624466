import React, {MutableRefObject, useMemo, useRef} from "react";
import {Button} from "@crossbuilders/form-library/components";
import {Tooltip as Tippy, TooltipProps} from 'react-tippy';

import {ReactComponent as IconUpload} from "../../../assets/images/icon-upload.svg";
import {ReactComponent as IconSee} from "../../../assets/images/eye-light.svg";

import {Gewerk} from "../../../@types/data/Darlehensdaten";
import {useModal} from "../../Modal/Modal";
import useStore from "../../../hooks/useStore";
import useDocumentDownload from '../../../hooks/useDocumentDownload';

const Tooltip = Tippy as unknown as React.FC<TooltipProps>;

interface TableActionCellProps {
    row: Gewerk;
    isEditingMode: boolean;
}

export default function TableOfProofOfUseActionCell({row, isEditingMode}: TableActionCellProps) {
    const {activeDarlehenId} = useStore();
    const {setModalOpen, setData, setModalType} = useModal();

    const {downloadDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    const rowDocuments = useMemo(() => {
        return row.dokumente.filter((dokument) => dokument.id !== null);
    }, [row]);

    return (
        <div className="table__cell-with-icons">
            {isEditingMode && rowDocuments.length < 5 ? (
                <Tooltip title="Jetzt hochladen" position="top" theme="light">
                    <Button
                        type="button"
                        onClick={() => {
                            setModalOpen(true);
                            setData({id: row.id});
                            setModalType("uploadProofOfUse");
                        }}
                    >
                        <IconUpload width="20" height="20" />
                    </Button>
                </Tooltip>
            ) : null}

            {rowDocuments.length > 0 ? (
                <Tooltip
                    title={rowDocuments.length > 1 ? 'Bilder ansehen' : 'Bild ansehen'}
                    position="top"
                    theme="light"
                >
                    <a
                        href={`#${row.id}`}
                        ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                        onClick={(event) => {
                            event.preventDefault();

                            if (rowDocuments.length > 1) {
                                setData(row);
                                setModalType('viewDocumentsList');
                                setModalOpen(true);
                            } else if (downloadButton.current) {
                                downloadDokument(downloadButton.current, activeDarlehenId, rowDocuments[0].id.toString());
                            }
                        }}
                    >
                        <IconSee width="20" height="20" />
                    </a>
                </Tooltip>
            ) : (
                <button
                    type="button"
                    disabled
                    style={{opacity: 0.5}}
                >
                    <IconSee width="20" height="20" />
                </button>
            )}
        </div>
    );
}

import React, {useMemo, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Tooltip as Tippy, TooltipProps} from 'react-tippy';

import 'react-tippy/dist/tippy.css';
import '../../assets/scss/6_components/layout/_table.scss';
import '../../assets/scss/6_components/general/_tag.scss';

import {ReactComponent as IconInfo} from '../../assets/images/circle-info-light.svg';
import {ReactComponent as SortIcon} from '../../assets/images/icon-arrow-down-red.svg';

import {TabContent} from '../../@types/dashboard';
import NoDataComponent from './partials/NoDataComponent';
import getStatusLabel, {Status} from '../../services/getStatusLabel';
import LoadingWheel from '../Layout/LoadingWheel';
import TableOfRequirementsActionCell from './partials/TableOfRequirementsActionCell';
import uniqueId from '../../services/uniqueIdService';
import {Auszahlungsvoraussetzung} from '../../@types/data/Darlehensdaten';

const Tooltip = Tippy as unknown as React.FC<TooltipProps>;

const requirementsStatusDictionary: {[key: string]: string} = {
    erledigt: 'erfüllt',
    nichtErforderlich: 'Verzicht',
    eingereicht: 'eingereicht',
    offen: 'offen',
};

const TableOfRequirements = ({data}: TabContent): JSX.Element => {
    const [isLoading] = useState(false);

    const columns = useMemo(
        (): TableColumn<Auszahlungsvoraussetzung>[] => [
            {
                name: 'Voraussetzungen',
                cell: (row) => (
                    <div className="table__cell-with-icon">
                        <span>{row.anzeigetext}</span>

                        {row.kundeninstruktion.length ? (
                            <Tooltip title={row.kundeninstruktion} position="top" theme="light">
                                <IconInfo width="20" height="20" />
                            </Tooltip>
                        ) : null}
                    </div>
                ),
                selector: (row) => row.anzeigetext,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Status',
                cell: (row) => {
                    const status = requirementsStatusDictionary[row.status];
                    return <span className={`tag tag--${getStatusLabel(status as Status)}`}>{status}</span>;
                },
                selector: (row) => row.status ?? '-',
                sortable: true,
                width: '150px',
            },
            {
                name: '',
                cell: (row) => <TableOfRequirementsActionCell row={row} />,
                right: true,
                width: '50px',
            },
        ],
        [data],
    );

    return (
        <>
            <div>
                {!isLoading ? (
                    <p className="text text--s tabs-content__intro">
                        Folgende Dokumente und Nachweise sind Grundvoraussetzungen für die
                        Dahrlehensauszahlung.
                    </p>
                ) : null}

                <DataTable
                    className="table"
                    columns={columns}
                    noDataComponent={<NoDataComponent />}
                    data={data}
                    responsive
                    progressPending={isLoading}
                    progressComponent={<LoadingWheel isLoading={isLoading} text="Daten werden geladen…" />}
                    sortIcon={<SortIcon />}
                    key={uniqueId()}
                />
            </div>
        </>
    );
};
export default TableOfRequirements;

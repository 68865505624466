import React from 'react';
import {useModal} from '../Modal';
import {Button} from "@crossbuilders/form-library/components";

const ConfirmationModal = () => {
    const {data, setModalOpen} = useModal();
    const {text, confirmButtonText, onConfirm} = data;

    return (
        <>
            <div className="modal__content">
                <h3 className="headline headline--level-2 headline--centered">{text}</h3>

                <section className="button-group button-group--align-center">
                    <Button
                        type="submit"
                        className="button button--primary"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        Abbrechen
                    </Button>

                    <Button
                        type="button"
                        className="button button--ghost"
                        onClick={() => {
                            onConfirm?.();
                            setModalOpen(false);
                        }}
                    >
                        {confirmButtonText}
                    </Button>
                </section>
            </div>
        </>
    );
};

export default ConfirmationModal;

import * as yup from 'yup';
import {parse, isValid, isFuture} from 'date-fns';
import {de} from 'date-fns/locale';
import {TestContext, ValidateOptions} from 'yup';

interface ValidateOptionsExtended extends ValidateOptions {
    parent: any;
    path: string;
    originalValue: any;
}

const fieldIsFilled = (value: string): boolean => !!value && !value.match(/[A-Z]/g);

const dateIsValid = (value: string) => {
    const parsedDate = parse(value, 'P', new Date(), {locale: de});
    return isValid(parsedDate);
};

const isFutureDate = (value: string) => isFuture(new Date(value));

const getOriginalPath = (path: string): string => {
    const splitPath = path.split('.');
    return splitPath.length > 1 ? splitPath[1] : splitPath[0];
};

const validateDateType = (context: TestContext, validationType: 'afterToday' | 'beforeOrEqual') => {
    const {createError, options} = context;
    const {originalValue, parent, path} = options as ValidateOptionsExtended;

    if (!path) {
        return true;
    }

    const originalPath = getOriginalPath(path);
    const isFieldVisible = parent && originalPath && originalPath in parent;

    if (!isFieldVisible) {
        return true;
    }

    if (!fieldIsFilled(originalValue)) {
        return createError({
            message: 'Bitte füllen Sie dieses Feld aus',
        });
    }

    if (validationType === 'afterToday' && !isFutureDate(originalValue)) {
        return createError({
            message: 'Das ausgewählte Datum muss in der Zukunft liegen',
        });
    }

    if (validationType === 'beforeOrEqual' && isFutureDate(originalValue)) {
        return createError({
            message: 'Das ausgewählte Datum darf nicht in der Zukunft liegen',
        });
    }

    return dateIsValid(originalValue);
};

export default function schemaRepository() {
    return {
        file: {
            required: yup.string().notOneOf(['', null], 'Bitte fügen Sie ein Dokument hinzu'),
        },
        number: {
            required: yup
                .string()
                .meta({isFormattedNumber: true})
                .notOneOf(['', null, 'NaN'], 'Bitte füllen Sie dieses Feld aus'),
            nullable: yup.string().meta({isFormattedNumber: true}).nullable(),
            nullableZero: yup
                .string()
                .meta({
                    isFormattedNumber: true,
                    zeroIfFalsy: true,
                })
                .nullable(),
            postalCode: yup
                .string()
                .meta({isFormattedNumber: true})
                .notOneOf(['', null, 'NaN'], 'Bitte füllen Sie dieses Feld aus')
                .min(5, 'Bitte geben sie eine 5-stellige Postleitzahl an.'),
            vertragsnummer: yup
                .string()
                .meta({isFormattedNumber: true})
                .notOneOf(['', null, 'NaN'], 'Bitte füllen Sie dieses Feld aus')
                .matches(/^\d+$/, {message: 'Die Vertragsnummer darf nur Ziffern enthalten', excludeEmptyString: true})
                .min(9, 'Die Vetragsnummer muss 9-10 Zeichen lang sein')
                .max(10, 'Die Vetragsnummer muss 9-10 Zeichen lang sein'),
            kontingent: yup
                .string()
                .meta({isFormattedNumber: true})
                .notOneOf(['', null, 'NaN'], 'Bitte füllen Sie dieses Feld aus')
                .max(6, 'Maximaler Wert sind 100.000€'),
        },
        string: {
            required: yup.string().trim().notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus'),
            nullableRequired: yup
                .string()
                .trim()
                .notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus')
                .nullable(true),
            nullable: yup.string().nullable(),
            'minLength-6': yup
                .string()
                .notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus')
                .min(6, 'Bitte geben Sie eine sechsstellige TAN ein'),
            'maxLength-10': yup
                .string()
                .notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus')
                .max(10, 'Es sind maximal 10 Zeichen erlaubt'),
            'maxLength-100': yup
                .string()
                .notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus')
                .max(100, 'Es sind maximal 100 Zeichen erlaubt'),
            'maxLength-140': yup
                .string()
                .notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus')
                .max(140, 'Es sind maximal 140 Zeichen erlaubt'),
            'maxLength-255': yup
                .string()
                .notOneOf(['', null], 'Bitte füllen Sie dieses Feld aus')
                .max(255, 'Es sind maximal 255 Zeichen erlaubt'),
        },
        radio: {
            required: yup.string().notOneOf(['', null], 'Bitte eine Option auswählen'),
        },
        select: {
            required: yup.string().notOneOf(['', null], 'Bitte auswählen'),
        },
        boolean: {
            required: yup.string().meta({isBoolean: true}).notOneOf(['', null], 'Bitte auswählen'),
            nullable: yup.string().meta({isBoolean: true}).nullable(),
        },
        date: {
            required: yup
                .string()
                .meta({isFormattedDate: true})
                .test('checkDateRequired', 'Bitte überprüfen Sie die Eingabe', function checkDateRequired() {
                    const {createError, options} = this;
                    const {originalValue, parent, path} = options as ValidateOptionsExtended;
                    const isFieldVisible = parent && path && path in parent;

                    if (!isFieldVisible) {
                        return true;
                    }

                    if (!fieldIsFilled(originalValue)) {
                        return createError({
                            message: 'Bitte geben Sie ein Ausführungsdatum an',
                        });
                    }

                    return dateIsValid(originalValue);
                })
                .nullable(true),
            beforeOrEqual: yup
                .string()
                .meta({isFormattedDate: true})
                .test(
                    'checkDateBeforeOrEqual',
                    'Bitte überprüfen Sie die Eingabe',
                    function checkDateBeforeOrEqual() {
                        return validateDateType(this, 'beforeOrEqual');
                    }
                )
                .nullable(true),
            afterToday: yup
                .string()
                .meta({isFormattedDate: true})
                .test(
                    'checkDateAfterToday',
                    'Bitte überprüfen Sie die Eingabe',
                    function checkDateAfterToday() {
                        return validateDateType(this, 'afterToday');
                    }
                )
                .nullable(true),
            cashOutDays: yup.date().min(new Date(), 'Das Datum darf frühestens morgen sein'),
        },
    };
}

import {useCallback, useEffect, useMemo, useState} from 'react';
import {useCookie, useLocalStorage} from 'react-use';
import {dispatch} from 'use-bus';
import axios from 'axios';
import Events from '../enum/Events';
import {Logger} from '../services/Logger';
import {Authenticate} from '../@types/authentication';
import useInitialState from './useInitialState';
import useStore from './useStore';
import {useTokenHandlerCookies} from './useTokenHandlerCookies';

export default function useAuthenticate(): Authenticate {
    const {
        REACT_APP_TOKENHANDLER_DOMAIN,
        REACT_APP_TOKENHANDLER_CSRF,
        REACT_APP_AUTH_TYPE_AUTH0,
        REACT_APP_AUTH_TYPE_ZKL,
        REACT_APP_TOKENHANDLER_USER_STATE,
        REACT_APP_TOKENHANDLER_LOGOUT,
    } = process.env;

    const [xbLogin, updateSwitchAuthCookie, deleteSwitchAuthCookie] = useCookie('XB_LOGIN');
    const [, , removePrevRouteFromLocalStorage] = useLocalStorage('sb-service-prevRoute');
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {csrfToken, expiresIn} = useTokenHandlerCookies();
    const {updateStore} = useStore();
    const initialState = useInitialState();

    const isAuthenticated = useMemo(
        () => !!(expiresIn?.length && expiresIn !== '0'),
        [expiresIn],
    );

    const tokenHandler = axios.create({
        baseURL: REACT_APP_TOKENHANDLER_DOMAIN,
        withCredentials: true,
        xsrfCookieName: '_csrf-token',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const userType = useMemo(
        () => (xbLogin ? REACT_APP_AUTH_TYPE_AUTH0 : REACT_APP_AUTH_TYPE_ZKL),
        [xbLogin],
    );

    const getCsrfToken = () => {
        return new Promise<void>((resolve, reject): void => {
            setIsLoading(true);

            if (!userType) {
                reject(new Error('[getCsrfToken] AuthType is undefined'));
                return;
            }

            if (csrfToken?.length) {
                resolve();
                return;
            }

            tokenHandler
                .post(`${REACT_APP_TOKENHANDLER_CSRF}`)
                .then(() => {
                    Logger.debug('[getCsrfToken] Success');
                    resolve();
                })
                .catch((error) => {
                    Logger.error('[getCsrfToken] Error', error);
                    reject(error);
                })
                .finally(() => setIsLoading(false));
        });
    };

    const getUserLoginState = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            setIsLoading(true);

            tokenHandler
                .get(`${REACT_APP_TOKENHANDLER_USER_STATE}`)
                .then(() => {
                    Logger.debug('[getUserLoginState] Success');
                    resolve();
                })
                .catch((error) => {
                    Logger.error('[getUserLoginState] Error', error);
                    reject(error);
                })
                .finally(() => setIsLoading(false));
        });
    };

    const handleAuthCookieChange = useCallback((): void => {
        if (xbLogin) {
            deleteSwitchAuthCookie();
        } else {
            updateSwitchAuthCookie('oauth', {secure: true, sameSite: 'Strict'});
        }
    }, [xbLogin, deleteSwitchAuthCookie, updateSwitchAuthCookie]);

    const signOut = async () => {
        await updateStore(initialState);
        await removePrevRouteFromLocalStorage();

        window.location.href = `${REACT_APP_TOKENHANDLER_LOGOUT}${userType}`;
    };

    useEffect(() => {
        if (isMounted) {
            dispatch({type: Events.SWITCH_AUTHENTICATION, value: {xbLogin}});
        }
    }, [xbLogin]);

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    return {
        isLoading,
        userType,
        setIsLoading,
        isAuthenticated,
        getCsrfToken,
        xbLogin,
        handleAuthCookieChange,
        getUserLoginState,
        signOut,
    };
}

import {Button, FormRow, Input} from '@crossbuilders/form-library/components';
import {format, parseISO} from 'date-fns';
import {FormEvent, useState} from 'react';
import {useForm} from 'react-hook-form';
import useBus, {dispatch} from 'use-bus';

import {Zahlungen, ZahlungenChanges} from '../../@types/data/Darlehensdaten';
import Events from '../../enum/Events';
import useApiService from '../../hooks/useApiService';
import useStore from '../../hooks/useStore';
import {cleanNumber, NumberFormat, transformToNumberFormat} from '../../services/numberConverterService';
import {postError, postSuccess} from '../../services/toastService';
import {useModal} from '../Modal/Modal';

type AuszahlungsauftragFormProps = {
    defaultValues: Record<string, any>;
    onSubmit: () => void;
};

export default function AuszahlungsauftragForm(props: AuszahlungsauftragFormProps) {
    const {defaultValues, onSubmit} = props;

    const {zahlungsauftragAblehnen, zahlungsauftragFreigeben} = useApiService();
    const {activeDarlehenId, activeDarlehen, updateActiveDarlehen} = useStore();
    const [noReasonSelected, setNoReasonSelected] = useState<boolean>(false);

    const [denyOption, setDenyOption] = useState<string>('');
    const [changedFields, setChangedFields] = useState<Array<string>>([]);
    const date = defaultValues?.ausfuehrungsdatum ? format(parseISO(defaultValues.ausfuehrungsdatum), 'd.M.Y') : 'sofort';

    const modalContext = useModal();
    const {setModalOpen, setModalType, setData: setModalData} = modalContext;

    const {
        control,
        errors,
        getValues,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onBlur',
        defaultValues: {
            ...defaultValues,
            date,
            betrag: `${transformToNumberFormat({number: defaultValues.betrag, digits: 2})} €`,
        },
    });

    const updateZahlungen = (changes: ZahlungenChanges) => {
        const {
            id,
            frontendStatus,
            abgelehntDurch,
            freigegebenDurch,
        } = changes;

        const zahlungen = activeDarlehen.zahlungen.map((zahlung: Zahlungen) => {
            if (zahlung.id === id) {
                return {
                    ...zahlung,
                    frontendStatus,
                    abgelehntDurch,
                    freigegebenDurch,
                };
            }

            return zahlung;
        });

        return updateActiveDarlehen({...activeDarlehen, zahlungen});
    };

    const submitHandler = (event: FormEvent) => {
        const form: HTMLFormElement = event.target as HTMLFormElement;
        if (form.checkValidity()) {
            event.preventDefault();
        }

        if (denyOption === 'changes' && changedFields.length === 0) {
            setNoReasonSelected(true);
            return;
        }

        const changes = denyOption === 'changes' ? changedFields : [];

        zahlungsauftragAblehnen(activeDarlehenId, defaultValues.id, changes)
            .then(updateZahlungen)
            .then(() => {
                postSuccess('Der Zahlungsauftrag wurde abgelehnt');

                onSubmit();
                dispatch(Events.SET_DARLEHENSDATEN);
            })
            .catch((error: string) => postError(error));
    };

    const approveZahlung = () => {
        zahlungsauftragFreigeben(activeDarlehenId, defaultValues.id)
            .then(updateZahlungen)
            .then(() => {
                postSuccess('Der Zahlungsauftrag wurde freigegeben');

                onSubmit();
                dispatch(Events.SET_DARLEHENSDATEN);
            })
            .catch((error: string) => postError(error));
    };

    useBus(
        Events.SUBMIT_AUSZAHLUNGSAUFTRAG_FORM,
        () => approveZahlung(),
        [activeDarlehenId],
    );

    const handleApproveButton = () => {
        if (cleanNumber(getValues('betrag'), '€', '', NumberFormat.COMMA) >= 100000) {
            setModalType('hintAzvValueTooHigh');
            setModalOpen(true);
            setModalData({
                onClose: () => {
                    setModalType('editPayout');
                    setModalData(defaultValues);
                    setModalOpen(true);
                },
            });
            return;
        }

        setModalType('confirmation');
        setModalData({
            text: 'Möchten Sie diese Zahlung freigeben?',
            confirmButtonText: 'Freigeben',
            onConfirm: () => approveZahlung(),
        });
        setModalOpen(true);
    };

    return (
        <form onSubmit={submitHandler} autoComplete="off">
            <section className="form-section">
                <FormRow className="column">
                    <Input
                        type="text"
                        id="empfaengerName"
                        name="empfaengerName"
                        placeholder="Empfängername"
                        labelClass="form-label text--grey"
                        control={control}
                        readOnly
                    >
                        Zahlungsempfänger
                    </Input>
                    <Input
                        type="text"
                        id="empfaengerIban"
                        name="empfaengerIban"
                        labelClass="form-label text--grey"
                        errors={errors}
                        control={control}
                        readOnly
                    >
                        IBAN
                    </Input>

                    <Input
                        type="text"
                        id="empfaengerKreditinstitut"
                        name="empfaengerKreditinstitut"
                        labelClass="form-label text--grey"
                        control={control}
                        errors={errors}
                        readOnly
                    >
                        Kreditinstitut
                    </Input>

                    <Input
                        type="text"
                        id="empfaengerBic"
                        name="empfaengerBic"
                        labelClass="form-label text--grey"
                        control={control}
                        errors={errors}
                        readOnly
                    >
                        BIC
                    </Input>

                    <Input
                        type="text"
                        id="betrag"
                        name="betrag"
                        labelClass="form-label text--grey"
                        control={control}
                        errors={errors}
                        readOnly
                    >
                        Gesamtbetrag
                    </Input>

                    <Input
                        type="text"
                        id="date"
                        name="date"
                        labelClass="form-label text--grey"
                        control={control}
                        errors={errors}
                        readOnly
                    >
                        Datum
                    </Input>
                </FormRow>
            </section>

            <section>
                <div className="form-row">
                    <input
                        checked={denyOption === 'deny'}
                        onChange={(event) => {
                            setDenyOption(event.target.checked ? 'deny' : '');

                            if (event.target.checked) {
                                setNoReasonSelected(false);
                            }
                        }}
                        type="checkbox"
                        id="deny"
                        value="deny"
                        name="denyOptions"
                        className="form-element form-element--checkbox"
                    />
                    <label htmlFor="deny" className="form-label">
                        Ihren Auftrag konnten wir nicht ausführen. Nähere Informationen erhalten Sie per
                        Post.
                    </label>
                </div>

                <div className="form-row">
                    <input
                        checked={denyOption === 'changes'}
                        onChange={(event) => {
                            setDenyOption(event.target.checked ? 'changes' : '');

                            if (!event.target.checked) {
                                setNoReasonSelected(false);
                            }
                        }}
                        type="checkbox"
                        id="changes"
                        value="changes"
                        name="denyOptions"
                        className="form-element form-element--checkbox"
                    />
                    <label htmlFor="changes" className="form-label">
                        Gerne führen wir Ihren Auftrag aus. Folgendes wurde von uns angepasst:
                        {noReasonSelected ?
                            <span className="form-hint--error">
                                &nbsp;Bitte wählen Sie den Grund der Anpassung.
                            </span> : null}
                    </label>
                </div>

                {denyOption === 'changes' ? (
                    <div className="form-row form-row--flex form-row--flex-wrap">
                        {['Betrag', 'IBAN', 'Verwendungszweck', 'Ausführungsdatum'].map((label) => (
                            <label className="form-group" key={label}>
                                <input
                                    checked={changedFields.includes(label)}
                                    onChange={(event) => {
                                        let fields = changedFields;
                                        if (event.target.checked) {
                                            fields = [...fields, event.target.name];
                                        } else {
                                            fields = fields.filter((value) => value !== event.target.name);
                                        }

                                        setNoReasonSelected(fields.length === 0);
                                        setChangedFields(fields);
                                    }}
                                    className="form-element form-element--checkbox"
                                    type="checkbox"
                                    name={label}
                                />

                                <span className="form-label">{label}</span>
                            </label>
                        ))}
                    </div>
                ) : null}
            </section>

            <section className="button-group button-group--align-end">
                <Button
                    type="submit"
                    className="button button--primary button--align-left"
                    onClick={() => true}
                    disabled={denyOption === ''}
                >
                    Ablehnen
                </Button>

                <Button
                    type="button"
                    className="button button--ghost button--align-right"
                    onClick={handleApproveButton}
                    disabled={denyOption !== ''}
                >
                    Freigeben
                </Button>
            </section>
        </form>
    );
}

import React, { useEffect } from "react";
import { dispatch } from "use-bus";
import { Outlet, useParams } from "react-router-dom";

import useStore from "../hooks/useStore";
import Events from "../enum/Events";

export default function DarlehenContainer() {
	const { darlehenId } = useParams();
	const { activeDarlehenId, updateStore } = useStore();

	useEffect(() => {
		console.log("[DarlehenContainer] darlehenId", darlehenId);
	}, [darlehenId]);

	useEffect(() => {
		if (!activeDarlehenId && darlehenId?.length) {
			updateStore({
				activeDarlehenId: darlehenId,
			});

			dispatch({ type: Events.PREPARE_DARLEHENSDATEN, value: { darlehenId } });
		}
	}, [activeDarlehenId, darlehenId]);

	return <Outlet />;
}

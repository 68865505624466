import React, {useMemo, useState, useEffect, useRef, MutableRefObject} from 'react';
import {format, parseISO} from 'date-fns';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Button} from '@crossbuilders/form-library/components';
import {Tooltip as Tippy, TooltipProps} from 'react-tippy';

import '../../assets/scss/6_components/layout/_table.scss';

import {ReactComponent as SortIcon} from '../../assets/images/icon-arrow-down-red.svg';
import {ReactComponent as IconView} from '../../assets/images/eye-light.svg';
import {ReactComponent as IconEdit} from '../../assets/images/icon-edit.svg';
import {ReactComponent as IconCheck} from '../../assets/images/check-regular.svg';

import {Dokument} from '../../@types/data/Darlehensdaten';
import {TableOfBillsProps} from '../../@types/dashboard';
import LoadingWheel from '../Layout/LoadingWheel';
import NoDataComponent from './partials/NoDataComponent';
import EditableTableCell from './partials/EditableTableCell';
import {transformToNumberFormat} from '../../services/numberConverterService';
import useApiService from '../../hooks/useApiService';
import useStore from '../../hooks/useStore';
import useIsMounted from '../../hooks/useIsMounted';
import useDocumentDownload from '../../hooks/useDocumentDownload';

interface TableComponentProps {
    data: Array<Dokument>,
    isEditingMode: boolean,
    isLoading: boolean,
    loadingText: string,
    onChange: (rowId: string, column: string, value: any) => void,
}

const Tooltip = Tippy as unknown as React.FC<TooltipProps>;

const TableComponent: React.FC<TableComponentProps> = (props: TableComponentProps) => {
    const {data, isEditingMode, isLoading, loadingText, onChange} = props;
    const {activeDarlehenId} = useStore();

    const {downloadDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    const columns = useMemo(
        (): Array<TableColumn<Dokument>> => [
            {
                name: 'Einreichungsdatum',
                selector: (row) => row.hochgeladenAm,
                format: (row) => format(parseISO(row.hochgeladenAm), 'dd.MM.y'),
                sortable: true,
                wrap: true,
                grow: 2,
                id: 'datum',
            },
            {
                name: 'Rechnungssteller',
                selector: (row) => row.rechnungsstellerName ?? '',
                cell: (row) => <EditableTableCell
                    row={row}
                    column="rechnungsstellerName"
                    onChange={onChange}
                    isEditingMode={isEditingMode}
                />,
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Betrag',
                selector: (row) => row.rechnungGesamtBrutto ?? 0,
                cell: (row) => <EditableTableCell
                    row={row}
                    column="rechnungGesamtBrutto"
                    type="number"
                    onChange={onChange}
                    isEditingMode={isEditingMode}
                />,
                sortable: true,
                wrap: true,
            },
            {
                name: '',
                cell: (row) => (
                    <div className="table__cell-with-icons">
                        <Tooltip title="Rechnung anzeigen" position="top" theme="light">
                            <a
                                href={`#${row.id}`}
                                ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                                onClick={(event) => {
                                    event.preventDefault();

                                    if (downloadButton.current) {
                                        downloadDokument(downloadButton.current, activeDarlehenId, row.id.toString());
                                    }
                                }}
                                type="button"
                            >
                                <IconView width="20" height="20" />
                            </a>
                        </Tooltip>
                    </div>
                ),
                right: true,
                width: '80px',
                allowOverflow: true,
            },
        ],
        [data, isEditingMode],
    );

    return (
        <div className="table">
            <DataTable
                className="table"
                columns={columns}
                data={data}
                responsive
                noDataComponent={<NoDataComponent />}
                sortIcon={<SortIcon />}
                progressPending={isLoading}
                progressComponent={<LoadingWheel isLoading={isLoading} text={loadingText} />}
                defaultSortFieldId="datum"
                defaultSortAsc={false}
            />
        </div>
    );
};

const TableOfBills = (props: TableOfBillsProps) => {
    const {data, rechnungsSumme, allowEditing, introText} = props;
    const {updateDokumente} = useApiService();
    const {activeDarlehenId} = useStore();
    const isMounted = useIsMounted();

    const [rechnungsSummeValue, setRechnungsSummeValue] = useState<number>(0);
    const [rowData, setRowData] = useState<Array<Dokument>>([]);
    const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [changedValues, setChangedValues] = useState<any>(null);

    const saveEdit = async () => {
        if (!isDirty) {
            setIsEditingMode(false);
            return;
        }

        setLoadingText('Bitte warten…');
        setIsLoading(true);

        const response = await updateDokumente(activeDarlehenId, changedValues);

        setIsEditingMode(false);
        setIsLoading(false);

        if (isMounted() && response.dokumente) {
            setRowData(response.dokumente);
            setRechnungsSummeValue(response.rechnungssumme);
        }
    };

    useEffect(() => {
        if (isMounted()) {
            setRowData(data);
        }
    }, [data, isMounted]);

    useEffect(() => {
        if (isMounted() && typeof rechnungsSumme === 'number') {
            setRechnungsSummeValue(rechnungsSumme);
        }
    }, [rechnungsSumme, isMounted]);

    if (!rowData) return null;

    return (
        <div>
            <p className="text text--s tabs-content__intro">
                {introText}
            </p>

            <p className="text text--s tabs-content__intro">
                <strong>Summe eingereichter Rechnungen: </strong>
                {!isLoading ? `${transformToNumberFormat({number: rechnungsSummeValue ?? 0, digits: 2})} €` : null}
            </p>

            {isEditingMode ? (
                <div className="tabs-content__actions">
                    <Button onClick={saveEdit} type="button" className="button button--small button--primary">
                        {isLoading ? (
                            <LoadingWheel isLoading={isLoading} color="white" />
                        ) : (
                            <IconCheck width="20" height="20" fill="#fff" />
                        )}
                        <span style={{marginLeft: '10px'}}>Änderungen speichern</span>
                    </Button>
                </div>
            ) : (allowEditing ? (
                <div className="tabs-content__actions">
                    <Button onClick={() => setIsEditingMode(true)} type="button"
                            className="button button--small button--ghost">
                        <IconEdit width="20" height="20" fill="#f84914" style={{marginRight: '10px'}} /> Rechnungsdaten
                        bearbeiten
                    </Button>
                </div>
            ) : null)}

            <TableComponent
                data={rowData}
                isEditingMode={isEditingMode}
                isLoading={isLoading}
                loadingText={loadingText}
                onChange={(rowId, column, value) => {
                    const rowIndex = data.findIndex(({id}) => id.toString() === rowId);
                    const newValues: Array<Dokument> = [...data];
                    // @ts-ignore
                    newValues[rowIndex][column as keyof Dokument] = value;

                    setChangedValues(newValues);

                    setIsDirty(true);
                }}
            />
        </div>
    );
};

export default TableOfBills;

import React, {useEffect} from "react";
import {dispatch} from "use-bus";

import "../assets/scss/6_components/layout/_tabs-content.scss";

import DashboardHeader from "../components/DashboardHeader/DashboardHeader";
import TableOfPayouts from "../components/Tables/TableOfPayouts";
import TableOfRequirements from "../components/Tables/TableOfRequirements";
import useStore from "../hooks/useStore";
import TableOfBills from "../components/Tables/TableOfBills";
import {
    Mahnstufe,
    Zahlungsstand,
    Bemerkung,
    Gewerk,
} from '../@types/data/Darlehensdaten';
import TableOfProofOfUse from "../components/Tables/TableOfProofOfUse";
import useIsMounted from "../hooks/useIsMounted";
import Events from "../enum/Events";
import {transformToNumberFormat} from "../services/numberConverterService";
import ContingentForm from "../components/Forms/ContingentForm";
import VerarbeitungssteuerungForm from "../components/Forms/VerarbeitungssteuerungForm";

const AdvisorPage = () => {
    const {activeDarlehen, baufortschritt, rechnungen} = useStore();
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted()) {
            dispatch({type: Events.IS_MOUNTED});
        }
    }, [isMounted]);

    if (!activeDarlehen) {
        return null;
    }

    const bautenstaende: Array<Gewerk> = baufortschritt?.gewerke ?? [];
    const bautenstandDatum: string = baufortschritt?.datum ?? '';

    return (
        <>
            <div className="seperated-by-lines" style={{paddingBottom: '10rem'}}>
                <div className="flow" id="uebersicht">
                    <DashboardHeader activeDarlehen={activeDarlehen} />
                </div>

                <div id="hinweise">
                    <h2 className="headline headline--level-2 headline--space-bottom">
                        Informationen aus dem Gesamtengagement
                    </h2>

                    <div className="two-column-grid">
                        <div className="key-value-box-wrapper">
                            <div className="key-value-box__headline">Hinweise:</div>
                            <dl className="key-value-box key-value-box--one-column">
                                {activeDarlehen.fehlerhinweise?.map((hinweis: string) => (
                                    <dd key={hinweis}>{hinweis}</dd>
                                ))}
                            </dl>
                        </div>

                        <div className="key-value-box-wrapper">
                            <div className="key-value-box__headline">Mahnstufen:</div>
                            <dl className="key-value-box">
                                {activeDarlehen.mahnstufen?.map((row: Mahnstufe, index: number) => (
                                    <React.Fragment key={`${index}-${row.vertragsnummer}`}>
                                        <dt>
                                            Vetragsnummer {row.vertragsnummer}
                                        </dt>
                                        <dd>
                                            Mahnstufe {row.mahnstufe}
                                        </dd>
                                    </React.Fragment>
                                ))}
                            </dl>
                        </div>

                        <div className="key-value-box-wrapper">
                            <div className="key-value-box__headline">Zahlungsstände:</div>
                            <dl className="key-value-box">
                                {activeDarlehen.zahlungsstaende?.map((row: Zahlungsstand, index: number) => (
                                    <React.Fragment key={`${index}-${row.vertragsnummer}`}>
                                        <dt>
                                            Vetragsnummer {row.vertragsnummer}
                                        </dt>
                                        <dd>
                                            {`${transformToNumberFormat({
                                                number: row.zahlungsstand ?? 0,
                                                digits: 2
                                            })} €`}
                                        </dd>
                                    </React.Fragment>
                                ))}
                            </dl>
                        </div>

                        <div className="key-value-box-wrapper">
                            <div className="key-value-box__headline">Abtretungen:</div>
                            <dl className="key-value-box">
                                {activeDarlehen.bemerkungen?.map((row: Bemerkung, index: number) => (
                                    <React.Fragment key={`${index}-${row.vertragsnummer}`}>
                                        <dt>
                                            Vetragsnummer {row.vertragsnummer}
                                        </dt>
                                        <dd>
                                            {row.bemerkung}
                                        </dd>
                                    </React.Fragment>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>

                <div id="voraussetzungen">
                    <h2 className="headline headline--level-2 headline--space-bottom">
                        Auszahlungsvoraussetzungen
                    </h2>

                    <TableOfRequirements data={activeDarlehen.auszahlungsvoraussetzungen} />
                </div>

                <div id="regeln">
                    <h2 className="headline headline--level-2 headline--space-bottom">
                        Verarbeitungssteuerung
                    </h2>

                    <VerarbeitungssteuerungForm />
                </div>

                <div id="kontingent">
                    <h2 className="headline headline--level-2 headline--space-bottom">
                        Kontingentsteuerung
                    </h2>

                    <ContingentForm />
                </div>

                <div id="auszahlungen">
                    <h2 className="headline headline--level-2 headline--space-bottom">Auszahlungen</h2>

                    <TableOfPayouts
                        data={activeDarlehen.zahlungen}
                        offeneAktivitaeten={activeDarlehen?.offeneAktivitaeten || []}
                        introText="Die Prüfung und Ausführung von Zahlungsaufträgen kann bis zu fünf Arbeitstage in Anspruch nehmen."
                    />
                </div>

                <div id="rechnungen">
                    <h2 className="headline headline--level-2 headline--space-bottom">Rechnungen</h2>

                    <TableOfBills
                        data={rechnungen}
                        rechnungsSumme={activeDarlehen.rechnungssumme ?? 0}
                        allowEditing={false}
                        introText="Hier finden Sie über das Auszahlungsportal eingereichte Rechnungen"
                    />
                </div>

                {bautenstaende?.length ? (
                    <div id="baufortschritt">
                        <h2 className="headline headline--level-2 headline--space-bottom">Baufortschritt</h2>

                        <TableOfProofOfUse
                            introText="Angaben zum Fortschritt der Bauarbeiten und Lichtbilder bei Neubauvorhaben"
                            allowEditing={false}
                            data={bautenstaende}
                            defaultDatum={bautenstandDatum}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default AdvisorPage;

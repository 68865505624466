import React from "react";
import { useNavigate } from "react-router-dom";
import ModalFooter from "../ModalFooter";
import ROUTES from "../../../enum/Routes";
import { ReactComponent as LogoInfo } from "../../../assets/images/icon-info.svg";
import useStore from "../../../hooks/useStore";

export default function HintUserLegitimation() {
	const navigate = useNavigate();
	const { activeDarlehenId } = useStore();

	const handleUserRedirect = () => {
		navigate(`${ROUTES.DASHBOARD}/${activeDarlehenId}/${ROUTES.PAYOUT_ORDER}`, {
			state: { preventRedirect: true }
		});
	};

	return (
		<>
			<div className="modal__content">
				<LogoInfo className="modal__headline-icon modal__headline-icon--centered" />

				<p className="text text--m text--centered">
					Zu Ihrer eigenen Sicherheit müssen wir vor der ersten
					Auszahlung einmalig Ihre Identität prüfen
				</p>
			</div>

			<ModalFooter buttons={[{ type: "abort" }, { type: "forward", onClick: handleUserRedirect }]} spread />
		</>
	);
}

import React, {useState, useCallback, useMemo} from 'react';
import useBus from 'use-bus';

import {ReactComponent as Logo} from '../../assets/images/WW_logo_cube.svg';

import useStore from '../../hooks/useStore';
import scrollToElement from '../../services/scrollingService';
import Events from '../../enum/Events';
import useAuthenticate from '../../hooks/useAuthenticate';

const PageSidebar = () => {
    const {activeDarlehenId, activeDarlehen, rechnungen, baufortschritt} = useStore();
    const {isAuthenticated, signOut} = useAuthenticate();

    const [activeNavItem, setActiveNavItem] = useState<string>('uebersicht');

    const navigationItems = useMemo(() => {
        const items: {[key: string]: string} = {
            'uebersicht': 'Finanzierungsübersicht',
            'hinweise': 'GE Informationen',
            'voraussetzungen': 'Auszahlungsvoraussetzungen',
            'regeln': 'Verarbeitungsregeln',
            'kontingent': 'Kontingent',
        };

        if (activeDarlehen?.zahlungen) {
            items.auszahlungen = 'Auszahlungen';
        }

        if (rechnungen.length) {
            items.rechnungen = 'Rechnungen';
        }

        if (baufortschritt?.gewerke) {
            items.baufortschritt = 'Baufortschritt';
        }

        return items;
    }, [activeDarlehen, baufortschritt]);

    const handleScroll = useCallback(() => {
        let closestOffset: number = NaN;
        let closestSection: string = '';

        Object.keys(navigationItems).forEach((id) => {
            const element = document.getElementById(id);
            if (!element) return;

            const {top, height} = element.getBoundingClientRect();

            if (top < height * -0.6) return;

            if (isNaN(closestOffset) || top < closestOffset) {
                closestOffset = top;
                closestSection = id;
            }
        });

        setActiveNavItem(closestSection);
    }, [navigationItems]);

    useBus(
        Events.IS_MOUNTED,
        () => {
            window.addEventListener('scroll', handleScroll);
        },
        [handleScroll],
    );

    const scrollTo = (key: string): void => {
        const element = document.getElementById(key);
        if (element) {
            scrollToElement(element);
        }
    };

    return (
        <aside className="page-sidebar">
            <a href="https://www.ww-ag.com/de" className="page-sidebar__logo">
                <Logo />
            </a>

            <nav className="page-sidebar__nav">
                <ul className="page-sidebar__nav-list">
                    {activeDarlehenId ? Object.keys(navigationItems).map((key: string) => (
                        <li
                            className={`page-sidebar__nav-item ${key === activeNavItem ? 'page-sidebar__nav-item--active' : ''}`}
                            onClick={() => scrollTo(key)}
                            key={key}
                        >
                            {navigationItems[key]}
                        </li>
                    )) : null}
                </ul>
            </nav>

            {isAuthenticated ? (
                <button className="page-sidebar__sign-out" onClick={() => signOut()}>
                    Abmelden
                </button>
            ) : null}
        </aside>
    );
};

export default PageSidebar;

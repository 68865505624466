import React, {MutableRefObject, useMemo, useRef} from 'react';
import {Tooltip as Tippy, TooltipProps} from 'react-tippy';
import {Auszahlungsvoraussetzung} from '../../../@types/data/Darlehensdaten';
import useStore from '../../../hooks/useStore';
import useDocumentDownload from '../../../hooks/useDocumentDownload';
import {useModal} from '../../Modal/Modal';

import {ReactComponent as IconSee} from '../../../assets/images/eye-light.svg';

interface TableActionCellProps {
    row: Auszahlungsvoraussetzung;
}

const Tooltip = Tippy as unknown as React.FC<TooltipProps>;

export default function TableOfRequirementsActionCell({row}: TableActionCellProps) {
    const {setModalOpen, setData, setModalType} = useModal();
    const {activeDarlehenId} = useStore();

    const {downloadDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    const rowDocuments = useMemo(() => {
        return row.dokumente.filter((dokument) => dokument.id !== null);
    }, [row]);

    return (
        <div className="table__cell-with-icons">
            {rowDocuments.length > 0 ? (
                <Tooltip
                    title={rowDocuments.length > 1 ? 'Bilder ansehen' : 'Bild ansehen'}
                    position="top"
                    theme="light"
                >
                    <a
                        href={`#${row.id}`}
                        ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                        onClick={(event) => {
                            event.preventDefault();

                            if (rowDocuments.length > 1) {
                                setData(row);
                                setModalType('viewDocumentsList');
                                setModalOpen(true);
                            } else if (downloadButton.current) {
                                downloadDokument(downloadButton.current, activeDarlehenId, rowDocuments[0].id.toString());
                            }
                        }}
                    >
                        <IconSee width="20" height="20" />
                    </a>
                </Tooltip>
            ) : (
                <button
                    type="button"
                    disabled
                    style={{opacity: 0.5}}
                >
                    <IconSee width="20" height="20" />
                </button>
            )}
        </div>
    );
}

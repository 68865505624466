import {InitialStoreParams, ReducerAction} from '../@types/store';

const reducers = (state: InitialStoreParams, action: ReducerAction): InitialStoreParams => {
	switch (action.type) {
		case 'updateStore':
			return {
				...state,
				...action.payload,
			};
		case 'setCurrentTabIndex':
			return {
				...state,
				currentTabIndex: action.payload,
			};
		case 'setTabs':
			return {
				...state,
				tabs: action.payload,
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export default reducers;

import React, {useMemo} from "react";
import {Dropdown} from "@crossbuilders/form-library/components";
import {Tooltip} from "react-tippy";
import {useForm} from "react-hook-form";

import {ReactComponent as IconInfo} from "../../assets/images/circle-info-light.svg";
import useApiService from '../../hooks/useApiService';

import useStore from "../../hooks/useStore";
import {Verarbeitungsregel} from "../../@types/data/Darlehensdaten";
import {postSuccess} from '../../services/toastService';

const VerarbeitungssteuerungForm = () => {
    const {activeDarlehen, activeDarlehenId} = useStore();
    const {updateVerarbeitungsregeln} = useApiService();

    const verarbeitungZahlungsauftraege = useMemo(() => {
        if (activeDarlehen?.zahlungsauftragsverarbeitungen) {
            const item = activeDarlehen?.zahlungsauftragsverarbeitungen
                ?.sort((a: Verarbeitungsregel, b: Verarbeitungsregel): number => {
                    return a.erstelltAm < b.erstelltAm ? 1 : -1;
                })[0];

            if (item?.dunkelverarbeitungErlaubt) {
                return 'dunkel';
            }
        }

        return 'hell';
    }, [activeDarlehen]);

    const verarbeitungBaustandsbericht = useMemo(() => {
        if (activeDarlehen?.nachweisverarbeitungen) {
            const item = activeDarlehen?.nachweisverarbeitungen
                ?.sort((a: Verarbeitungsregel, b: Verarbeitungsregel): number => {
                    return a.erstelltAm < b.erstelltAm ? 1 : -1;
                })[0];

            if (item?.dunkelverarbeitungErlaubt) {
                return 'dunkel';
            }
        }

        return 'hell';
    }, [activeDarlehen]);


    const formMethods = useForm({
        defaultValues: {
            verarbeitungZahlungsauftraege,
            verarbeitungBaustandsbericht,
        },
    });
    const {control, handleSubmit} = formMethods;

    const submitForm = (data: { verarbeitungBaustandsbericht: string, verarbeitungZahlungsauftraege: string }) => {
        updateVerarbeitungsregeln(activeDarlehenId, {
            nachweiseDunkelverarbeitungErlaubt: data.verarbeitungBaustandsbericht === 'dunkel',
            zahlungsauftraegeDunkelverarbeitungErlaubt: data.verarbeitungZahlungsauftraege === 'dunkel',
        }).then(() => {
            postSuccess('Änderungen wurden erfolgreich gespeichert.');
        });
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <div className="form-row form-row--flex">
                <div className="form-row form-row--50 form-row--horizontal">
                    <Dropdown
                        id="verarbeitung-zahlungsauftraege"
                        name="verarbeitungZahlungsauftraege"
                        control={control}
                        rowClass=""
                        choices={{
                            dunkel: "Dunkel",
                            hell: "Hell",
                        }}
                        defaultValue="default"
                    >
                        Verarbeitung Zahlungsaufträge:
                    </Dropdown>

                    <Tooltip
                        title="Dunkel: Zahlungsaufträge werden ohne Prüfung bzw. Freigabe eines Sachbearbeiters verarbeitet. Hell: Zahlungsaufträge erzeugen eine offene Aktivität. Die Prüfung und ggfs. Freigabe muss manuell durch einen Sachbearbeiter erfolgen."
                        position="top"
                        theme="light"
                    >
                        <IconInfo width="20" height="20" />
                    </Tooltip>
                </div>

                <div className="form-row form-row--50 form-row--horizontal">
                    <Dropdown
                        id="verarbeitung-baustandsbericht"
                        name="verarbeitungBaustandsbericht"
                        control={control}
                        rowClass=""
                        choices={{
                            dunkel: "Dunkel",
                            hell: "Hell",
                        }}
                        defaultValue="default"
                    >
                        Verarbeitung Rechnungen/ Bautenstandsbericht:
                    </Dropdown>

                    <Tooltip
                        title="Dunkel: Dokumente werden ohne Aktivität im KAP abgelegt. Hell: Dokumente sind manuell im KAP zu prüfen."
                        position="top"
                        theme="light"
                    >
                        <IconInfo width="20" height="20" />
                    </Tooltip>
                </div>
            </div>

            <input
                type="submit"
                className="button button--primary button--bottom-space button--align-right"
                value="Speichern"
            />
        </form>
    );
};

export default VerarbeitungssteuerungForm;

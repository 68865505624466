import React from 'react';
import {useModal} from '../Modal';
import AuszahlungsauftragForm from "../../Forms/AuszahlungsauftragForm";
import ModalHeader from "../ModalHeader";

const EditPayout = () => {
    const {data, setModalOpen} = useModal();

    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h3 className="headline headline--level-2">Auszahlungsauftrag</h3>

                <AuszahlungsauftragForm
                    defaultValues={data}
                    onSubmit={() => setModalOpen(false)}
                />
            </div>
        </>
    );
};

export default EditPayout;

import React, {MutableRefObject, useMemo, useRef} from 'react';
import {format, parseISO} from 'date-fns';
import DataTable, {TableColumn} from 'react-data-table-component';
import ModalHeader from '../ModalHeader';
import {useModal} from '../Modal';
import NoDataComponent from '../../Tables/partials/NoDataComponent';
import uniqueId from '../../../services/uniqueIdService';
import {ReactComponent as SortIcon} from '../../../assets/images/icon-arrow-down-red.svg';
import {Dokument} from '../../../@types/data/Darlehensdaten';
import useStore from '../../../hooks/useStore';
import useDocumentDownload from '../../../hooks/useDocumentDownload';

const ViewDocumentsList = () => {
    const {activeDarlehenId} = useStore();
    const {data, setModalOpen} = useModal();

    const {downloadDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    // TODO: Verlinkung Dokumentname

    const dokumente = useMemo((): Array<Dokument> => {
        if (!Array.isArray(data.dokumente)) return [];

        return data.dokumente;
    }, [data]);

    const columns = useMemo(
        (): Array<TableColumn<Dokument>> => [
            {
                name: 'Dokument',
                cell: (row: Dokument) => (
                    <div className="table__cell-with-icon">
                        <a
                            href={`#${row.id}`}
                            ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                            className="button button--cta-simple"
                            onClick={(event) => {
                                event.preventDefault();

                                if (downloadButton.current) {
                                    downloadDokument(downloadButton.current, activeDarlehenId, row.id.toString());
                                    setModalOpen(false);
                                }
                            }}
                        >
                            {row.name}
                        </a>
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                grow: 2,
            },
            {
                id: 'date',
                name: 'Datum',
                cell: (row) => <span>{format(parseISO(row.hochgeladenAm), 'dd.MM.y')}</span>,
                selector: (row) => row.hochgeladenAm,
                sortable: true,
            },
        ],
        [dokumente],
    );

    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <DataTable
                    className="table"
                    columns={columns}
                    noDataComponent={<NoDataComponent />}
                    data={dokumente}
                    responsive
                    sortIcon={<SortIcon />}
                    key={uniqueId()}
                    defaultSortFieldId="date"
                    defaultSortAsc={false}
                />
            </div>
        </>
    );
};

export default ViewDocumentsList;

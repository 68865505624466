import {useCookie} from 'react-use';
import {Dispatch} from 'react';

export interface TokenHandlerCookies {
    csrfToken: string | null;
    expiresIn: string | null;
    deleteCsrfToken: Dispatch<void>;
    deleteExpiresIn: Dispatch<void>;
}

export function useTokenHandlerCookies(): TokenHandlerCookies {
    const [csrfToken, , deleteCsrfToken] = useCookie('_csrf-token');
    const [expiresIn, , deleteExpiresIn] = useCookie('_expires-in');

    return {
        csrfToken,
        expiresIn,
        deleteCsrfToken,
        deleteExpiresIn,
    };
}

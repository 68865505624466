import React, {useMemo} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {format, parseISO} from 'date-fns';
import 'react-tippy/dist/tippy.css';

import '../../assets/scss/6_components/layout/_table.scss';
import '../../assets/scss/6_components/general/_tag.scss';

import {ReactComponent as SortIcon} from '../../assets/images/icon-arrow-down-red.svg';

import NoDataComponent from './partials/NoDataComponent';
import LoadingWheel from '../Layout/LoadingWheel';
import uniqueId from '../../services/uniqueIdService';
import {Kontingent} from '../../@types/data/Darlehensdaten';
import {transformToNumberFormat} from '../../services/numberConverterService';

const TableOfKontingente = ({data, isLoading}: {data: Array<Kontingent>, isLoading: boolean}): JSX.Element => {
    const columns = useMemo(
        (): TableColumn<Kontingent>[] => [
            {
                name: 'Änderungsdatum',
                selector: (row) => row.erstelltAm,
                format: (row) => format(parseISO(row.erstelltAm), 'dd.MM.y'),
                sortable: true,
                wrap: true,
                id: 'datum',
            },
            {
                name: 'Betrag',
                cell: (row) => (
                    <div className="table__cell-with-icon">
                        <span>{`${transformToNumberFormat({number: row.betrag ?? 0, digits: 2})} €`}</span>
                    </div>
                ),
                selector: (row) => row.betrag,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Benutzer',
                selector: (row) => row.erstelltDurch,
                cell: (row) => row.erstelltDurch,
                sortable: true,
                wrap: true,
            },
        ],
        [data],
    );

    return (
        <>
            <div>
                <DataTable
                    className="table"
                    columns={columns}
                    noDataComponent={<NoDataComponent />}
                    data={data}
                    responsive
                    progressPending={isLoading}
                    progressComponent={<LoadingWheel isLoading={isLoading} text="Daten werden geladen…" />}
                    sortIcon={<SortIcon />}
                    key={uniqueId()}
                    pagination
                    defaultSortFieldId="datum"
                    defaultSortAsc={false}
                />
            </div>
        </>
    );
};
export default TableOfKontingente;

import React, {createContext, useContext, useState} from 'react';
import {createPortal} from 'react-dom';

import '../../assets/scss/6_components/layout/_modal.scss';

import {ModalProps} from '../../@types/dashboard';
import HintAzvValueTooHigh from './ModalContent/HintAzvValueTooHigh';
import ViewDocumentsList from './ModalContent/ViewDocumentsList';
import SuccessfulUpload from './ModalContent/SuccessfulUpload';
import RemoveDocument from './ModalContent/RemoveDocument';
import uniqueId from '../../services/uniqueIdService';
import OpenTextarea from './ModalContent/OpenTextarea';
import RemoveTableRow from './ModalContent/RemoveTableRow';
import SeePayout from './ModalContent/SeePayout';
import HintUserLegitimation from './ModalContent/HintUserLegitimation';
import EditPayout from './ModalContent/EditPayout';
import ConfirmationModal from './ModalContent/ConfirmationModal';

const initialModalContext: ModalProps = {
    data: {},
    modalOpen: false,
    modalType: '',
    setModalOpen: () => {},
    setModalType: () => {},
    setData: () => {},
};

export const ModalContext = createContext<ModalProps>(initialModalContext);

const modalContentDictionary: {[key: string]: React.ReactNode} = {
    viewDocumentsList: <ViewDocumentsList key={uniqueId()} />,
    hintUserLegitimation: <HintUserLegitimation />,
    removeDocument: <RemoveDocument key={uniqueId()} />,
    successfulUpload: <SuccessfulUpload key={uniqueId()} />,
    openTextarea: <OpenTextarea key={uniqueId()} />,
    removeTableRows: <RemoveTableRow key={uniqueId()} />,
    seePayout: <SeePayout key={uniqueId()} />,
    editPayout: <EditPayout key={uniqueId()} />,
    confirmation: <ConfirmationModal key={uniqueId()} />,
    hintAzvValueTooHigh: <HintAzvValueTooHigh key={uniqueId()} />,
};

export const Modal = () => {
    const modalRoot = document.getElementById('modal');
    const modalContext = useModal();

    if (!modalContext?.modalOpen) return null;

    return createPortal(
        <div className="modal-wrapper">
            <div className="modal-background" onClick={() => modalContext.setModalOpen(false)} />
            <div className="modal" id="modal-content">
                {modalContentDictionary[modalContext?.modalType] ?? null}
            </div>
        </div>,
        modalRoot as HTMLDivElement,
    );
};

interface ModalContextProviderProps {
    children: React.ReactNode;
}

export const ModalContextProvider = ({children}: ModalContextProviderProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [data, setData] = useState({});

    return (
        <>
            <ModalContext.Provider
                value={{
                    modalOpen,
                    setModalOpen,
                    modalType,
                    setModalType,
                    data,
                    setData,
                }}
            >
                {children}
            </ModalContext.Provider>
        </>
    );
};

export const useModal = (): ModalProps => useContext(ModalContext);
